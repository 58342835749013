import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Icon, Loader, Button } from 'semantic-ui-react'


import PopupWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'
import ContactCard from '../../../../../../../common-components/ContactCard'
import RevealFetchErrorDetails from '../../../../../../../common-components/fetchErrorDetails/RevealFetchErrorDetails'

import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../../../../../constants'

import {
  extractMostImportantDetailsFromApiErrorObject,
} from '../../../../../../../utils'


import './styles/DownloadInvoiceButton.css'


import {
  FETCH_DOWNLOAD_INVOICE,
} from '../../../../../../../redux/actions/actionTypes'
import createAction from '../../../../../../../redux/actions/createAction'

// We connect because we need dispatch
export default connect()(({
  customerId,
  info,
  dispatch,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [hasFetchFailed, setHasFetchFailed] = useState(false)
  const [errorDetails, setErrorDetails] = useState(null)

  const handleClick = () => {
    const promise = new Promise((resolve, reject) => {
      dispatch(createAction(
        FETCH_DOWNLOAD_INVOICE,
        {
          customerId,
          invoiceMetadata: info,
          resolve,
          reject,
        },
      ))
    })
    setIsFetching(true)
    promise.then(() => {
      setIsFetching(false)
      setHasFetchFailed(false)
      setErrorDetails(null)
    }).catch(error => {
      setIsFetching(false)
      setHasFetchFailed(true)
      const myErrorDetails = error
        ? extractMostImportantDetailsFromApiErrorObject({ error })
        : null
      setErrorDetails(myErrorDetails)
    })
  }

  if (isFetching) {
    return (
      <Loader
        active
        inline
        size="tiny"
      />
    )
  }
  if (hasFetchFailed) {
    return (
      <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        trigger={
          <Icon
            key={`submitFailed${1}`}
            link
            name="exclamation circle"
            color="red"
          />
        }
        on="click"
        wide="very"
        position="bottom left"
        className="semantic-ui-error-border"
      >
        <div>
          <p>{DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR}</p>
          <ContactCard customerId={customerId} repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} />
          <Button
            color="blue"
            onClick={handleClick}
          >
            Retry Download
          </Button>
          {errorDetails &&
            <RevealFetchErrorDetails
              errorDetails={errorDetails}
            />
          }
        </div>
      </PopupWithCatastrophicFailureErrorBoundary>
    )
  }
  return (
    <Icon
      link // makes icon act like a button on hover (cursor arrow changes to hand/pointing finger)
      className="bold-on-hover"
      name="file pdf outline"
      color="red"
      onClick={handleClick}
    />
  )
})
