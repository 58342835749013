import moment from 'moment'
import qs from 'qs'
import { parsePhoneNumber } from 'react-phone-number-input'
import { matchPath } from 'react-router'

import get_ from 'lodash/get'
import has_ from 'lodash/has'
import some_ from 'lodash/some'
import every_ from 'lodash/every'
import sortBy_ from 'lodash/sortBy'
import orderBy_ from 'lodash/orderBy'
import omit_ from 'lodash/omit'
import range_ from 'lodash/range'
import isEqual_ from 'lodash/isEqual'
import isNumber_ from 'lodash/isNumber'
import isString_ from 'lodash/isString'
import isPlainObject_ from 'lodash/isPlainObject'
import isArray_ from 'lodash/isArray'
import isFunction_ from 'lodash/isFunction'
import overEvery_ from 'lodash/overEvery'
import mergeWith_ from 'lodash/mergeWith'
import uniq_ from 'lodash/uniq'
import difference_ from 'lodash/difference'
import keys_ from 'lodash/keys'
import countBy_ from 'lodash/countBy'
import entries_ from 'lodash/entries'
import head_ from 'lodash/head'
import last_ from 'lodash/last'
import maxBy_ from 'lodash/maxBy'
import partialRight_ from 'lodash/partialRight'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import keysFp_ from 'lodash/fp/keys'
import valuesFp_ from 'lodash/fp/values'
import entriesFp_ from 'lodash/fp/entries'
import flattenFp_ from 'lodash/fp/flatten'
import uniqFp_ from 'lodash/fp/uniq'
import filterFp_ from 'lodash/fp/filter'
import reduceFp_ from 'lodash/fp/reduce'


import getSymbolFromCurrency from 'currency-symbol-map'
import capitalize_ from 'lodash/capitalize'
import {
  URL_PATHS,
  URL_PATH_OPERATE_FOR_STRING,
  URL_PATH_DPS_CUSTOMER_ID,
  URL_PATH_DPS_ADDRESS_ID,

  API_URL_PATH_CUSTOMER,
  API_URL_PATH_CHILDREN,
  API_URL_PATH_ADDRESSES,
  API_URL_PATH_INDIVIDUAL_ADDRESS,
  API_URL_PATH_CONTRACTS,
  API_URL_PATH_RELATED_TO_CUSTOMER_INFO,
  API_URL_PATH_RELATED_FROM_CUSTOMER_INFO,
  API_URL_PATH_RELATED_TO_RELATIONSHIP_INFO,
  API_URL_PATH_RELATED_FROM_RELATIONSHIP_INFO,
  API_URL_PATH_CUSTOMER_REPRESENTATIVE,
  API_URL_PATH_INDIVIDUAL_CONTACT,
  URL_PATH_DPS_CONTACT_ID,
  API_URL_PATH_INDIVIDUAL_CUSTOMER_CONTACT_LINK,
  URL_PATH_DPS_CUSTOMER_CONTACT_LINK_ID,

  API_URL_PATH_INDIVIDUAL_KEG_ORDER,
  API_URL_PATH_DPS_KEG_ORDER_UUID,

  API_URL_PATH_INDIVIDUAL_COLLAR_ORDER,
  API_URL_PATH_DPS_COLLAR_ORDER_UUID,

  API_URL_PATH_INDIVIDUAL_SHIPMENT,
  API_URL_PATH_EDIT_USER_REPORTED_SHIPMENT,
  API_URL_PATH_DPS_SHIPMENT_UUID,

  API_URL_PATH_DPS_USER_ID,

  API_URL_PATH_REQUEST_NEW_DISTRIBUTOR,
  URL_PATH_DPS_DISTRIBUTOR_CUSTOMER_ID,

  API_URL_PATH_DPS_AMAZON_REQUEST_ID,
  API_URL_POLL_TIMED_OUT_API_FETCH,

  API_URL_PATH_DPS_S3_KEY,
  API_URL_PATH_DOWNLOAD_INVOICE,

  API_URL_PATH_CUSTOMER_USER,
  API_URL_PATH_PERMISSIONS,

  CUSTOMER_TYPES_TO_HUMAN_READABLE_NAME_MAP,

  CONTAINER_TYPES,

  BUSINESS_UNIT_ID_TO_NAME_MAP,

  USER_TYPE_EMPLOYEE,

  ALL_UNIVERSAL_CUSTOMER_REPS,

  ENVIRONMENT_DEV,
  ENVIRONMENT_TEST,
  ENVIRONMENT_PROD,

  DEFAULT_LABEL_WIDTHS_FOR_HORIZONTAL_FORM_SECTION,

  CONCATENATED_STRING_SEPARATOR,
  PASSWORD_INPUT_TOO_LONG_ERROR_MSG,

  API_MAINTENANCE_HTTP_ERROR_CODES,
  API_TIMED_TOKEN_HTTP_HEADER_KEY,

  US_STATES,
  COUNTRIES_WHOSE_CALLING_CODE_IS_ONE,
} from '../constants'

import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../constants/formAndApiUrlConfig/commonConfig'

import {
  LOGIN_PASSWORD_MIN_LENGTH,
  LOGIN_PASSWORD_MAX_LENGTH,
} from '../config'

import { NestedPropNotInObjectError } from '../customErrors'

import packageJson from '../../package.json'
import { isPurposeOfShipmentToFulfillKegOrder } from '../features/History/individualTabs/util/util'
// import { getContainerTypeFromItemSkuId } from '../redux/selectors/rewrite/itemSkus'


/*
 * *****************************************************************************
 * Auth token
 * *****************************************************************************
*/

/**
 *  expiry: Unix epoch timestamp
 */
export function getIsUserTokenExpired(expiry) {
  return moment.unix(expiry) < moment() // CODE_COMMENTS_7
}


export function getIsUserTokenWithinXMinutesOfExpiringButNotExpired(expiry, minutes) {
  if (getIsUserTokenExpired(expiry)) { return false }
  return moment.unix(expiry) < moment().add(minutes, 'minutes')
}


/*
 * *****************************************************************************
 * URL Paths construction
 * *****************************************************************************
*/

// App URLs
// -------------------------------------

export function getOperateForCustomerUrl(customerId) {
  return URL_PATHS.operateForCustomer.path.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}


// Relative URLs
// -------------------------------------

/**
 * returns the nearest upward '.../operate-for/:customerId' route:
 *
 * /operate-for/1234m/order-kegs --> /operate-for/1234m
 * /operate-for/1234m/operate-for/5678x/order-kegs --> /operate-for/1234m/operate-for/5678x
 *
 * Here's a tricky one that this function handles correctly:
 *
 * /operate-for/1234m/operate-for --> /operate-for/1234m
 *
 * Technically this won't actually go to the customer portal if there's no way
 * to get up to a customer portal route within the path--it will just go to the
 * root route. For instance, if you pass in '/login', it will take you to '/';
 * same if you pass in '/contact-rep', etc.
 */
export function goUpToCustomerPortal(path) {
  const pathParts = path.split('/')
  if (!pathParts.includes(URL_PATH_OPERATE_FOR_STRING)) { return URL_PATHS.root.path }

  // if the path ends with '/operate-for', remove that right-most
  // '/operate-for'. The while loop takes care of silly,
  // never-likely-to-encounter paths such as
  // '/operate-for/1234m/operate-for/operate-for/operate-for'
  while (pathParts[pathParts.length - 1] === URL_PATH_OPERATE_FOR_STRING) {
    pathParts.pop()
    if (!pathParts.includes(URL_PATH_OPERATE_FOR_STRING)) { return URL_PATHS.root.path }
  }
  return pathParts.slice(0, pathParts.lastIndexOf(URL_PATH_OPERATE_FOR_STRING) + 2).join('/')
}


// Returns the path part immediately after the right-most 'operating-for':

// /operate-for/1234m/order-kegs --> '1234m'
// /operate-for/1234m/operate-for/5678x/order-kegs --> '5678x'

// If the right-most '/operate-for' has no part immediately after it, returns
// the part immediately after the right-most-minus-one part:

// /operate-for/1234m/operate-for --> '1234m'

// If there's no part after any 'operate-for', or if there's no 'operate-for' in
// the path at all, returns undefined:

// /blah-blah/operate-for --> undefined

// Use this function sparingly; see CODE_COMMENTS_121
export function getCustomerIdOfCustomerCurrentlyBeingOperatedForFromUrlPath(path) {
  const nearestCustomerPortalPath = goUpToCustomerPortal(path)
  if (nearestCustomerPortalPath === URL_PATHS.root.path) { return undefined }
  const pathParts = nearestCustomerPortalPath.split('/')
  return pathParts[pathParts.length - 1]
}


// /operate-for/1234m/order-kegs --> ['1234m']
// /operate-for/1234m/operate-for/5678x/order-kegs --> ['1234m', '5678x']
// /order-kegs --> []
// /operate-for/operate-for/operate-for/1234m/operate-for/ --> ['1234m']

// Use this function sparingly; see CODE_COMMENTS_121
export function getCustomerIdsOfAllOperatedForCustomersInUrlPath(path) {
  const pathParts = path.split('/')
  return pathParts.filter((pathPart, index) => {
    if (index === 0) { return false }
    return (
      pathPart !== URL_PATH_OPERATE_FOR_STRING &&
      pathPart[index - 1] === URL_PATH_OPERATE_FOR_STRING
    )
  })
}


// API URLs
// -------------------------------------

export function getCustomerApiUrl(customerId) {
  return API_URL_PATH_CUSTOMER.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}

export function getCustomerChildrenApiUrl(customerId) {
  return API_URL_PATH_CHILDREN.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}

export function getAddressesApiUrl() {
  return API_URL_PATH_ADDRESSES
}

export function getIndividualAddressApiUrl(addressId) {
  return API_URL_PATH_INDIVIDUAL_ADDRESS.replace(
    URL_PATH_DPS_ADDRESS_ID,
    addressId,
  )
}

export function getContractsApiUrl(customerId) {
  return API_URL_PATH_CONTRACTS.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}

export function getRelatedToCustomerInfoApiUrl(customerId) {
  return API_URL_PATH_RELATED_TO_CUSTOMER_INFO.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}

export function getRelatedFromCustomerInfoApiUrl(customerId) {
  return API_URL_PATH_RELATED_FROM_CUSTOMER_INFO.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}

export function getRelatedToRelationshipInfoApiUrl(customerId) {
  return API_URL_PATH_RELATED_TO_RELATIONSHIP_INFO.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}

export function getRelatedFromRelationshipInfoApiUrl(customerId) {
  return API_URL_PATH_RELATED_FROM_RELATIONSHIP_INFO.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    customerId,
  )
}

export function getIndividualKegOrderApiUrl(orderUuid) {
  return API_URL_PATH_INDIVIDUAL_KEG_ORDER.replace(
    API_URL_PATH_DPS_KEG_ORDER_UUID,
    orderUuid,
  )
}

export function getIndividualCollarOrderApiUrl(orderUuid) {
  return API_URL_PATH_INDIVIDUAL_COLLAR_ORDER.replace(
    API_URL_PATH_DPS_COLLAR_ORDER_UUID,
    orderUuid,
  )
}

export function getIndividualShipmentApiUrl(shipmentUuid) {
  return API_URL_PATH_INDIVIDUAL_SHIPMENT.replace(
    API_URL_PATH_DPS_SHIPMENT_UUID,
    shipmentUuid,
  )
}

// CODE_COMMENTS_136
export function getEditIndividualUserReportedShipmentApiUrl(shipmentUuid) {
  return API_URL_PATH_EDIT_USER_REPORTED_SHIPMENT.replace(
    API_URL_PATH_DPS_SHIPMENT_UUID,
    shipmentUuid,
  )
}

export function getCustomerRepresentativeApiUrl(repId) {
  return API_URL_PATH_CUSTOMER_REPRESENTATIVE.replace(
    API_URL_PATH_DPS_USER_ID,
    repId,
  )
}

export function getRequestNewDistributorApiUrl(brwCustId, distCustId) {
  const withBrwId = API_URL_PATH_REQUEST_NEW_DISTRIBUTOR.replace(
    URL_PATH_DPS_CUSTOMER_ID,
    brwCustId,
  )
  return withBrwId.replace(
    URL_PATH_DPS_DISTRIBUTOR_CUSTOMER_ID,
    distCustId,
  )
}

export function getCustomerUserApiUrl(userId) {
  return API_URL_PATH_CUSTOMER_USER.replace(
    API_URL_PATH_DPS_USER_ID,
    userId,
  )
}

export function getUserPermissionsApiUrl(userId) {
  return API_URL_PATH_PERMISSIONS.replace(
    API_URL_PATH_DPS_USER_ID,
    userId,
  )
}

export function getIndividualContactApiUrl(contactId) {
  return API_URL_PATH_INDIVIDUAL_CONTACT.replace(
    URL_PATH_DPS_CONTACT_ID,
    contactId,
  )
}

export function getIndividualCustomerContactLinkApiUrl(customerContactLinkId) {
  return API_URL_PATH_INDIVIDUAL_CUSTOMER_CONTACT_LINK.replace(
    URL_PATH_DPS_CUSTOMER_CONTACT_LINK_ID,
    customerContactLinkId,
  )
}

export function getPollTimedOutApiFetchApiUrl(amazonRequestId) {
  return API_URL_POLL_TIMED_OUT_API_FETCH.replace(
    API_URL_PATH_DPS_AMAZON_REQUEST_ID,
    amazonRequestId,
  )
}

export function getDownloadInvoiceApiUrl(s3Key) {
  return API_URL_PATH_DOWNLOAD_INVOICE.replace(
    API_URL_PATH_DPS_S3_KEY,
    s3Key,
  )
}

/*
 * *****************************************************************************
 * Misc. URL Paths functions
 * *****************************************************************************
*/

/**
 * Returns a relative path which equals the current path taken from a React
 * Router `match` object concatenated with the `path` string passed in. For
 * example:
 *
 * match = { url: '/operate-for'}
 * path = '/1234m'
 * returns: '/operate-for/1234m'
 *
 * If match.url is '/', don't prepend the new path with it:
 *
 * match = { url: '/'}
 * path = '/order-kegs'
 * returns: '/order-kegs', not '//order-kegs'
 *
 *
 */
export const createRelativePath = (match, path) => (
  `${match.url === '/' ? '' : match.url}${path}`
)


/**
 * Simple URL path parser that returns a path navigated up x levels. For
 * example:
 *
 * goUpInUrlPath('/operate-for/1234m', 1) --> '/operate-for'
 * goUpInUrlPath('/operate-for/1234m', 2) --> '/'
 *
 * trailing slashes in the "path" attribute are ignored: a path attr of
 * '/operate-for/1234m' returns the exact same thing as a path attr of
 * '/operate-for/1234m/'
 *
 * Leading slashes aren't messed with--what goes in goes out:
 *
 * goUpInUrlPath('operate-for/1234m', 1) --> 'operate-for'
 * goUpInUrlPath('operate-for/1234m', 2) --> ''
 *
 * Returned value never has a trailing slash.
 */
export const goUpInUrlPath = (path, numLevels) => {
  let pathParts = path.split('/')
  const doesPathHaveLeadingSlash = path.slice(0) === '/'
  const doesPathHaveTrailingSlash = path.slice(-1) === '/'
  // if the path has a trailing slash, remove final item in array, which will be
  // an empty string, thereby making it as though the path passed in had no
  // trailing slash.
  if (doesPathHaveTrailingSlash) { pathParts = pathParts.slice(0, -1) }
  const chopped = pathParts.slice(0, -numLevels)
  const stringToReturn = chopped.join('/')
  //
  if (stringToReturn === '') {
    return doesPathHaveLeadingSlash
      ? '/'
      : ''
  }

  return stringToReturn
}


// https://stackoverflow.com/questions/6680825/return-string-without-trailing-slash#comment11853012_6680877
export function removeTrailingSlashesFromUrlPath(path) {
  return path.replace(/\/+$/, '')
}


// https://stackoverflow.com/a/23584219
export function replaceMultipleForwardSlashesWithOne(path) {
  return path.replace(/\/\/+/g, '/')
}


// Simply checks to see if the first path part is 'employee', e.g.
//
// /employee
// /employee/operate-as-customer-user
// ...etc
//
// The 'path' prop should be a string, and you can get it from React
// Router's location.pathname
export function getIsEmployeePath(path) {
  return path.split('/')[1].toLowerCase() === 'employee'
}

// returns a subset of URL_PATHS. See CODE_COMMENTS_267 for what faux private
// paths are.
export function getAllFauxPrivatePaths({
  // if True, returns an array of strings rather than a subset of URL_PATHS
  returnJustPathsInsteadOfPathObjs = false,
}) {
  return flow_([
    keysFp_,
    filterFp_(key => ([
      'isDistReportInventoryNoLoginRequired',
      'isUnsubscriptionNoLoginRequired',
      'isDistReportConstellationNoLoginRequired',
    ].includes(key))),
    ...(returnJustPathsInsteadOfPathObjs
      ? [mapFp_(key => URL_PATHS[key].path)]
      : [reduceFp_((acc, key) => ({ ...acc, [key]: URL_PATHS[key] }))]
    ),
  ])(URL_PATHS)
}

// CODE_COMMENTS_267
export function getIsFauxPrivatePath(path) {
  const fauxPrivatePaths = getAllFauxPrivatePaths({ returnJustPathsInsteadOfPathObjs: true })
  return fauxPrivatePaths.some(fauxPrivatePath => (
    matchPath(
      path,
      {
        path: fauxPrivatePath,
        exact: true,
      },
    )
  ))
}

// CODE_COMMENTS_269
export function parseUrlQueryParametersString(query) {
  const vars = query.split('&')
  const queryString = {}
  for (let i = 0; i < vars.length; i+=1) {
    const pair = vars[i].split('=')
    const key = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1])
    // If first entry with this name
    if (typeof queryString[key] === 'undefined') {
      queryString[key] = decodeURIComponent(value)
      // If second entry with this name
    } else if (typeof queryString[key] === 'string') {
      const arr = [queryString[key], decodeURIComponent(value)]
      queryString[key] = arr
      // If third or later entry with this name
    } else {
      queryString[key].push(decodeURIComponent(value))
    }
  }
  return queryString
}


// CODE_COMMENTS_269
export function getQueryParametersFromUrl(
  location, // the location object from react-router
) {
  // location.search is the query parameters in single string form, e.g.
  // "?expiration=168423010000&mstartoken=ASDFASDFASDFAS". React Router doesn't
  // break them out for you; see CODE_COMMENTS_269.
  return parseUrlQueryParametersString(location.search)
}

/*
 * *****************************************************************************
 * Working with the API
 * *****************************************************************************
*/

/**
 * When you send the API a date string to save, you format it as 'YYYY-MM-DD'.
 * The backend converts and saves this to a Unix Timestamp with the time
 * information set to Midnight GMT. When you request date information from the
 * API, you get might back that unix timestamp in milliseconds (e.g.
 * 1513728000000) or you might get back the date string you gave it formatted as
 * 'YYYY-MM-DD' (depends on the query; for instance, /orders sends back a unix
 * timestamp in all its date field props [dateOrdered, dateNeeded, etc], but
 * /shipments sends a YYYY-MM-DD string in all its date field props
 * [dateShipped, dateReported, etc]). This function determines which type a date
 * from an API is.
 */
export const determineApiDateType = apiDate => (
  isString(apiDate)
    ? DEFAULT_API_DATE_FORMAT
    : 'unix timestamp'
)


export const convertApiDateToMoment = (
  apiDate,
  useUtc = false, // Unusual, but useful for things like the NoMovements feature
) => {
  if (determineApiDateType(apiDate) === DEFAULT_API_DATE_FORMAT) {
    return useUtc
      ? moment.utc(apiDate, DEFAULT_API_DATE_FORMAT)
      : moment(apiDate, DEFAULT_API_DATE_FORMAT)
  }
  // the apiDate is a unix timestamp. You might think that formatting to a
  // string and then back to a moment is unnecessary, but it is.
  const dateString = useUtc
    ? moment.utc(apiDate).format(DEFAULT_API_DATE_FORMAT)
    : moment(apiDate).format(DEFAULT_API_DATE_FORMAT)
  return useUtc
    ? moment.utc(dateString, DEFAULT_API_DATE_FORMAT)
    : moment(dateString, DEFAULT_API_DATE_FORMAT)
}


// When calling GET /shipments, we sometimes want to get all the shipments in
// both statusA and statusB (for example all delivered shipments and all
// in-transit shipments). To do this, we set the status query param to all our
// target statuses separated by an underscore, e.g. 'DEL_INTRAN'
export const createMultipleStatusesQueryParamValueForShipmentsApiCall = (...statuses) => (
  statuses.join('_')
)


// `responseOrErrorObj` can be either the object returned by a successful XHR
// call or the object returned by an unsuccessful (4xx/5xx error) XHR call.
// Returns undefined if no amazon request id exists in the object.
export const extractAmazonRequestIdFromHttpResponseOrErrorObject = responseOrErrorObj => (
  // if error object
  get_(responseOrErrorObj, ['response', 'headers', 'x-amzn-requestid']) ||
  // if response object
  get_(responseOrErrorObj, ['headers', 'x-amzn-requestid'])
)


export function extractMostImportantDetailsFromApiErrorObject({
  error={}, // the returned HTTP Error object
}) {
  if (error?.response) {
    const { status, statusText, data, headers } = error.response
    const { url, params, method } = error.config
    const urlWithParams = params
      ? createUrlStringWithParams(url, params)
      : url
    return {
      errorCode: status,
      errorMessage: statusText,
      responseBody: data,
      amazonRequestId: headers['x-amzn-requestid'],
      url: urlWithParams,
      method,
      errorObject: error,
    }
  }
  return {
    errorCode: null,
    errorMessage: null,
    responseBody: null,
    amazonRequestId: null,
    url: null,
    method: null,
    errorObject: error,
  }
}

export function getDoesHttpErrorIndicateThatBackendIsUnderMaintentance({
  error, // the returned HTTP Error object
}) {
  const statusCode = get_(error, ['response', 'status'])
  return API_MAINTENANCE_HTTP_ERROR_CODES.includes(statusCode)
}


function createUrlStringWithParams(url, params) {
  const queryString = qs.stringify(
    params,
    // by default, Axios converts spaces to '+' rather than to '%20'. We want to
    // make sure our output follows the same convention. See
    // https://www.npmjs.com/package/qs#rfc-3986-and-rfc-1738-space-encoding
    { format: 'RFC1738' },
  )
  return `${url}?${queryString}`
}


export function getWhichConfigObjectMatchesError({
  error, // error object returned by axios fetch
  configObjects,
}) {
  const fullUrlCalled = error.config.url
  return configObjects.find(configObject => {
    const fullUrlOfConfigObject = `${process.env.REACT_APP_API_ROOT}${configObject.path}`
    return fullUrlOfConfigObject === fullUrlCalled
  })
}


// responseOrErrorObject = {
//   config: {
//     baseURL: 'http://www.someurl.com',
//     url: http://www.someurl.com/some/path/to/page'
//   },
// }
// -> '/some/path/to/page'
//
// if baseURL is the same as url, returns '/'.
export function getApiUrlPathFromHttpResponseOrErrorObject(resposneOrError) {
  // if this is a network error or CORS error, the error object will be an empty
  // object
  if (!has_(resposneOrError, ['config'])) { return undefined }

  let baseURL = resposneOrError.config.baseURL
  if (!baseURL) { baseURL = process.env.REACT_APP_API_ROOT }

  const split = resposneOrError.config.url.split(baseURL)
  let apiUrlPath = split[split.length - 1]

  if (!apiUrlPath.startsWith('/')) {
    apiUrlPath = `/${apiUrlPath}`
  }
  return apiUrlPath
}

// responseOrErrorObject = {
//   config: {
//     baseURL: 'http://www.someurl.com',
//     url: http://www.someurl.com/some/path/to/page'
//   },
// }
// -> '/some/path/to/page'
//
// if baseURL is the same as url, returns '/'.
export function getMethodFromHttpResponseOrErrorObject(resposneOrError, automaticallyCapitalize = true) {
  // if this is a network error or CORS error, the error object will be an empty
  // object
  if (!has_(resposneOrError, ['config', 'method'])) { return undefined }
  const method = resposneOrError.config.method
  return automaticallyCapitalize
    ? method.toUpperCase()
    : method
}


// For now, when someone logs in, the user object returned does not contain a
// `userTypeId` prop. So for now, the way to differentiate between a customer
// user and an employee user is to check the 'rootCustomerId' prop: if it's
// falsy, it's an employee.
export function getIsUserAnEmployee(userObj) {
  if (has_(userObj, 'userTypeId')) {
    return userObj.userTypeId === USER_TYPE_EMPLOYEE
  }
  return !userObj.rootCustomerId
}

// CODE_COMMENTS_267
export function createApiTimedTokenHttpHeader(token) {
  return {
    Authorization: `${API_TIMED_TOKEN_HTTP_HEADER_KEY} ${token}`,
  }
}


/*
 * *****************************************************************************
 * Environments (dev, test, prod, etc.)
 * *****************************************************************************
*/

// Returns the string 'development', 'test' or 'production' based on the root
// API URL, assuming that the root API URL is one of these:
// * https://api.dev.microstarlogistics.com
// * https://dev-api.microstarlogistics.com
// * https://test-api.microstarlogistics.com
// * https://prod-api.microstarlogistics.com
// * https://api.microstarlogistics.com
// If the root API URL isn't one of these, returns the subdomains (everything
// after the https:// and before the .microstarlogistics.com, e.g. 'api.dev' or
// 'dev-api'); if root API URL doesn't have any subdomains, returns the entire
// root API URL.
export function getEnvironmentBasedOnRootApiUrl() {
  const environments = {
    // the names of the environments (the values of this object) should be
    // unabbreviated lower-case strings, as recommended by Rollbar:
    // https://docs.rollbar.com/docs/environments#section-recommended-usage
    'prod-api': ENVIRONMENT_PROD,
    api: ENVIRONMENT_PROD,
    'api.tst': ENVIRONMENT_TEST,
    'test-api': ENVIRONMENT_TEST,
    'dev-api': ENVIRONMENT_DEV,
    'api.dev': ENVIRONMENT_DEV,
  }

  let apiRoot = process.env.REACT_APP_API_ROOT
  // See the 'Avoiding CORS by Proxying API Requests in Development' section of
  // the PACKAGE.JSON.md docs file.
  if (apiRoot.includes('localhost')) {
    apiRoot = packageJson.proxy
  }

  const allSubdomains = getAllSubdomainsOfUrl(apiRoot).join('.')
  return allSubdomains
    ? (
      environments[allSubdomains] ||
      // If due to a bug we have a unknown allSubdomains not listed in the
      // `environments` object above, name the environment whatever the
      // allSubdomains is.
      allSubdomains
    )
    // If due to a bug the domain has no subdomian, name the environment the
    // entire API root URL.
    : apiRoot
}


/* *****************************************************************************
 * Determining types
 * *****************************************************************************
*/

/**
 * https://stackoverflow.com/a/9436948
 */
export function isString(myVar) {
  return typeof myVar === 'string' || myVar instanceof String
}


/**
 * https://stackoverflow.com/a/9716488
 */
export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}


/**
 * https://stackoverflow.com/a/4775741
 * But you should probably just use Array.isArray(myVar) in the actual code.
 */
export function isArray(myVar) {
  return Array.isArray(myVar)
}


/**
 * https://stackoverflow.com/a/8511350
 */
export function isNonArrayObject(myVar) {
  return typeof myVar === 'object' && myVar !== null && !Array.isArray(myVar)
}


/*
 * *****************************************************************************
 * Working with objects
 * *****************************************************************************
*/

/**
 * Returns the nested slice of an object (works with arrays too). The slices
 * args must be in the proper order.
 */
export function drillDownInObject(state, ...slices) {
  if (!doesNestedPropExistInObj(state, ...slices)) {
    throw new NestedPropNotInObjectError(`The slice ${slices.join('.')} does not exist in the object`)
  }
  return slices.reduce((drilledDownState, slice) => drilledDownState[slice], state)
}


/**
 * Say you have an object whose values are also objects:
 *
 * const obj = {
 *   item1: { key1: 'blah1', key2: 'blah11' },
 *   item2: { key1: 'blah2', key2: 'blah22' },
 * }
 *
 * This function returns the first subobject with a key-value pair match.
 * Returns null if no match is found.
 */
export function findSubobjectByMatchingProperty(obj, key, value) {
  const filtered = Object.keys(obj)
    .filter(objKey => obj[objKey][key] === value)
  if (filtered.length) {
    return obj[filtered[0]]
  }
  return null
}

/**
 * Often you want to make sure of two things at the same time: 1) that a
 * variable is not null, undefined or NaN; 2) that, if the variable is an array
 * or object, it's not empty (or technically, has enumerable properties). This
 * function does that. If you pass in a number, 0 and negative #s return false,
 * positives return true. Empty strings return false, non-empty true.
 */
export function isTruthyAndNonEmpty(obj) {
  if (!obj) { return false }
  return Object.keys(obj).length > 0
}


/**
 * Check for the existence of nested object key. This will return true even if
 * the final key's value is null/undefined/false. For instance, if the object
 * is:
 *
 * const store = {
 *   customersAddresses: {
 *     byCustomerId: {
 *       "id-1": undefined,
 *     }
 *   }
 * }
 *
 * doesNestedPropExistInObj(store, 'customersAddresses', 'byCustomerId', 'id-1')
 * --> true
 *
 * https://stackoverflow.com/a/2631198
 */
export function doesNestedPropExistInObj(obj, ...args) {
  for (let i = 0; i < args.length; i += 1) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false
    }
    // eslint-disable-next-line no-param-reassign
    obj = obj[args[i]]
  }
  return true
}


/**
 * const arr = [
   { SB: 15, HB: 10 },
   { QB: 15, HB: 10 },
   { SB: 15, HB: 10 },
   { SB: 15, YY: 10 },
   { SB: 15, HB: 10 },
 ]
 ->
 [ 'SB', 'HB', 'QB', 'YY' ]
 */
/* eslint-disable no-multi-spaces */
export const getAllUniqueKeysOfArrayOfObjects = arr => (
  flow_(
    mapFp_(keysFp_),    // turn the array of sub-objects into an array of arrays of keys
    flattenFp_,       // turn array of arrays into single array
    uniqFp_,          // dedupe the array
  )(arr)
)
/* eslint-enable no-multi-spaces */


/**
 * const obj = {
   one: { SB: 15, HB: 10 },
   two: { QB: 15, HB: 10 },
   three: { SB: 15, HB: 10 },
   four: { SB: 15, YY: 10 },
   five: { SB: 15, HB: 10 },
 }
 ->
 [ 'SB', 'HB', 'QB', 'YY' ]
 */
/* eslint-disable no-multi-spaces */
export const getAllUniqueKeysOfAllSubobjects = obj => (
  getAllUniqueKeysOfArrayOfObjects(valuesFp_(obj))   // turn the object into an array of sub-objects
)
/* eslint-enable no-multi-spaces */

/**
 * props should be an array
 */
export function doSelectObjPropsAllExistAndContainTruthyValues(
  obj,
  props,
  considerIntZeroATruthyValue = false,
) {
  return considerIntZeroATruthyValue
    ? props.every(prop => (obj[prop] || obj[prop] === 0))
    : props.every(prop => obj[prop])
}

/**
 * props should be an array
 */
export function doesAtLeastOneObjPropExistAndContainATruthyValue(obj, props) {
  return props.some(prop => obj[prop])
}

/**
 * A practical example: let's say you want to get all unique container types
 * (by unique, we mean that the returned array has no duplicates):
 *
 *
 * const obj = {
 *   id1: {
 *     requestedKegs: { SB: 15, HB: 50 },
 *     localKegs: { SB: 99, HB: 99 },
 *   },
 *   id2: {
 *     requestedKegs: { SB: 15, HB: 50 },
 *     localKegs: { QB: 88, HB: 88 },
 *   }
 * }
 *
 * f(obj, 'requestedKegs', 'localKegs')
 * ->
 * [ 'SB', 'HB', 'QB' ]
 *
 */
export function getAllUniqueKeysInAllSubobjects(obj, ...keys) {
  return flow_(
    // mapFp_(o => [o.requestedKegs, o.localKegs]),
    mapFp_(o => keys.map(key => o[key])),
    flattenFp_,
    mapFp_(keysFp_),
    flattenFp_,
    uniqFp_,
  )(valuesFp_(obj))
}


/**
 * Turn this:
 *
 * [
 *   { section: 'Section 1', otherProp: 'blah', ... },
 *   { section: 'Section 2', otherProp: 'shma', ... },
 *   { section: 'Section 1', otherProp: 'cha', ... },
 * ]
 *
 * into this:
 *
 * [
 *   [
 *     { section: 'Section 1', otherProp: 'blah', ...},
 *     { section: 'Section 1', otherProp: 'cha', ...}
 *   ],
 *   [
 *     { section: 'Section 2', otherProp: 'shma', ...}
 *   ],
 * ]
 *
 * Order is important: the first sublist is of the first propName encountered
 * in the original array, second the second, etc.
 */
export const reorganizeArrayOfObjectsByProp = (arr, propName) => {
  const propValues = flow_(
    mapFp_(item => item[propName]),
    uniqFp_,
  )(arr)

  return arr.reduce((acc, item) => {
    if (!acc[propValues.indexOf(item[propName])]) {
      acc[propValues.indexOf(item[propName])] = []
    }
    acc[propValues.indexOf(item[propName])].push(item)
    return acc
  }, [])
}


// See unit tests for explanation. Notes:
//
// * Only objects whose keys match will be included in the returned array: if an
// object in arr1 has a key prop that doesn't match the key prop of any object
// in arr2, that object will be omitted from the returned array.
//
// * If identical keys exist in matching objects, the props in arr2 override
// those in arr1
//
// * Comparisons are done using lodash's isEqual() method, so you the prop
// values can be arrays and objects as well as just strings and numbers.
export const combineTwoArraysOfObjectsByKeyMatch = (arr1, arr2, keyInArr1, keyInArr2) => (
  // first, filter out subobjects whose keys don't match in both arrays
  arr1.filter(arr1SubObj => (arr2.find(arr2SubObj => isEqual_(arr2SubObj[keyInArr2], arr1SubObj[keyInArr1]))))
    // next, create the new subobjects
    .map(arr1SubObj => {
      const matchingArr2SubObj = arr2.find(arr2SubObj => isEqual_(arr2SubObj[keyInArr2], arr1SubObj[keyInArr1]))
      return Object.assign({}, arr1SubObj, matchingArr2SubObj)
    })
)

// See unit tests for explanation.
export const replacePropKeysWithAliases = (arr, aliasesMap) => {
  // convert [{ prop: 'id', alias: 'customerId'}] to [{ id: 'customerId' }]
  const newAliasesMap = aliasesMap.reduce(
    (acc, aliasDef) => Object.assign({}, acc, { [aliasDef.prop]: aliasDef.alias }),
    {},
  )

  const keysThatHaveAliases = Object.keys(newAliasesMap)
  return arr.map(obj => (
    Object.keys(obj).reduce((acc, propKey) => {
      if (keysThatHaveAliases.includes(propKey)) {
        // return { ...acc, [newAliasesMap[propKey]]: obj[propKey]}
        return Object.assign({}, acc, { [newAliasesMap[propKey]]: obj[propKey] })
      }
      // return { ...acc, [propKey]: obj[propKey]}
      return Object.assign({}, acc, { [propKey]: obj[propKey] })
    }, {})
  ))
}


// Dedupes the result of
// getPathsOfAllSubObjectsThatHaveSpecificCharacteristicsINTERNALFUNCTIONONLY(),
// see the docstring of that function for details.
export function getPathsOfAllSubObjectsThatHaveSpecificCharacteristics(props) {
  const resultWithPotentialDuplicates =
    getPathsOfAllSubObjectsThatHaveSpecificCharacteristicsINTERNALFUNCTIONONLY(props)
  // This does not work: `uniqBy_(resultWithPotentialDuplicates, isEqual_)`, so
  // we have to manually make these unique

  // Why use specialConcatenatedStringSeparatorJustForThisFunction rather than
  // our regular concatenated string separator assigned to the variable
  // CONCATENATED_STRING_SEPARATOR? Because what if any of our strings in
  // resultWithPotentialDuplicates contain CONCATENATED_STRING_SEPARATOR? The
  // final step in this function, the split(), will mess up our results. We need
  // a string separator that is likely completely unique.
  const specialConcatenatedStringSeparatorJustForThisFunction = '^$#--__--#$^'
  const resultsAsStrings = resultWithPotentialDuplicates.map(
    arr => arr.join(specialConcatenatedStringSeparatorJustForThisFunction),
  )
  const uniqueResultsAsStrings = uniq_(resultsAsStrings)
  return uniqueResultsAsStrings.map(str => str.split(specialConcatenatedStringSeparatorJustForThisFunction))
}

// Pass in an object or array and this recursively searches for all subobjects
// which pass the subObjectsFilterFunc. Returns an array of arrays of strings
// representing the paths to all the subobjects relative to the top of the
// passed-in obj. Returns an empty array if no subobjects that pass
// subObjectsFilterFunc are found or if the passed-in argument is not an object
// or array (string, boolean, etc). If the object passed in passes
// subObjectsFilterFunc itself, a one-item array is returned containing
// currentNestedPath, e.g. if currentNestedPath=['hello'], then [['hello']] is
// returned. NOTE: This function has a bug where if the top-level object passed
// in passes the subObjectsFilterFunc, the top-level object can get repeated
// over and over in the returned value: [[currentNestedPath],
// [currentNestedPath], [currentNestedPath]]. This gets fixed an another
// function which calls this one and dedupes the result.
function getPathsOfAllSubObjectsThatHaveSpecificCharacteristicsINTERNALFUNCTIONONLY({
  obj,
  subObjectsFilterFunc,
  // The only requirement here is that objects that pass this function should
  // return a non-null value when keysFp_() is called on them
  iterateOverTheseKindsOfObjectsFunc = o => isPlainObject_(o) || isArray_(o),
  // if this is the first time this function is being called (i.e. if this call
  // was not made by itself, recursively), currentNestedPath should be a
  // one-item array containing a string representing the top of the object. Why
  // is this necessary? Because we need a way to distinguish between the case
  // where an object is passed in that has no fetchStatus objects (in which case
  // an empty array is returned) and an object which is itself a fetchStatus
  // object (in which case [[currentNestedPath]] is returned).
  currentNestedPath,
  currentArrayOfPaths = [],
}) {
  if (!iterateOverTheseKindsOfObjectsFunc(obj)) {
    return currentArrayOfPaths
  }

  let newCurrentArrayOfPaths
  if (
    iterateOverTheseKindsOfObjectsFunc(obj) &&
    subObjectsFilterFunc(obj)
  ) {
    newCurrentArrayOfPaths = [
      ...currentArrayOfPaths,
      currentNestedPath,
    ]
  } else {
    newCurrentArrayOfPaths = currentArrayOfPaths
  }

  // If obj is an array, returns e.g. ['0', '1', '2'], which is exactly what we
  // want (remember that arr['0'] does exactly the same as arr[0])
  const whatToIterateOver = keysFp_(obj)

  let toReturn = whatToIterateOver.map(keyOrArrayIndex => (
    getPathsOfAllSubObjectsThatHaveSpecificCharacteristics({
      obj: obj[keyOrArrayIndex],
      subObjectsFilterFunc,
      iterateOverTheseKindsOfObjectsFunc,
      currentNestedPath: currentNestedPath.concat([keyOrArrayIndex]),
      currentArrayOfPaths: newCurrentArrayOfPaths,
    })
  ))

  // Because of the recursion going on in this function, the default behavior is
  // to keep wrapping arrays of paths in their own arrays again and again, which
  // is not what we want. The following code corrects that behavior.
  if (toReturn.length > 0) {
    while (!toReturn.every(pathArray => (
      isString_(pathArray[0]) ||
      isNumber_(pathArray[0])
    ))) {
      toReturn = flattenFp_(toReturn)
    }
  }
  return toReturn
}


export function swapKeysAndValuesOfObject(obj) {
  return Object.entries(obj).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [v]: k,
    }),
    {},
  )
}


// Pass in an object whose values are one-dimensional arrays of strings/numbers
// and this function reverses the keys and values. See tests for examples.
export function reverseKeysAndValuesOfObjectOfFlatArrays(objOfFlatArrays) {
  return Object.keys(objOfFlatArrays).reduce(
    (acc, key) => {
      // we use `|| []` in case a value is not an array
      const flatArray = objOfFlatArrays[key] || []
      const flatArrayOfStrings = flatArray.map(item => item.toString())
      const objOfFlatArray = flatArrayOfStrings.reduce(
        (accum, item) => ({ ...accum, [item]: [key] }),
        {},
      )

      return mergeWith_(
        acc,
        objOfFlatArray,
        // https://lodash.com/docs/4.17.11#mergeWith, see example.
        (a, b) => {
          const toCombinea = isArray_(a) ? a : []
          const toCombineb = isArray_(b) ? b : []
          return toCombinea.concat(toCombineb)
        },
      )
    },
    {},
  )
}


// This function uses a heuristic to check whether an object is an Axios Error/Response object
// object.
export function isAxiosErrorObject(obj) {
  return (
    has_(obj, 'config') &&
    has_(obj, 'request') &&
    has_(obj, 'response')
  )
}

// This function uses a heuristic to check whether an object is an Axios Error/Response object
// object.
export function isAxiosResponseObject(obj) {
  return (
    has_(obj, 'config') &&
    has_(obj, 'request') &&
    has_(obj, 'headers') &&
    has_(obj, 'data')
  )
}

// This function uses a heuristic to check whether an object is an Axios Error/Response object
// object.
export function isAxiosErrorOrResponseObject(obj) {
  return isAxiosErrorObject(obj) || isAxiosResponseObject(obj)
}


// https://stackoverflow.com/a/52323412/6995996
export function shallowCompare(obj1, obj2) {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    // eslint-disable-next-line no-prototype-builtins
    Object.keys(obj1).every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key],
    )
  )
}


// Reduces multiple objects to a single object:
// {
//   isQueued: false,
//   hasFetchBeenAttempted: true,
//   isFetching: true,
//   didFetchSucceed: false,
//   didFetchFail: false,
// }
export function createAggregateFetchStatuses({
  // an array of objects: [
  //   { isQueued: false, hasFetchBeenAttempted: true, isFetching: false, didFetchSucceed: true, didFetchFail: false },
  //   { isQueued: false, hasFetchBeenAttempted: true, isFetching: false, didFetchSucceed: false, didFetchFail: true },
  //   { isQueued: false, hasFetchBeenAttempted: true, isFetching: true, didFetchSucceed: false, didFetchFail: false },
  // ]
  // It's OK if some objects are missing certain props (such as isQueued); such
  // missing props will be ignored (their values count as neither true nor
  // false, but rather get filtered out).
  fetchStatusObjects,
  // sane defaults
  isQueuedMustBeTrueForAllRatherThanForJustOne = false,
  hasFetchBeenAttemptedMustBeTrueForAllRatherThanForJustOne = false,
  isFetchingMustBeTrueForAllRatherThanForJustOne = false,
  didFetchSucceedMustBeTrueForAllRatherThanForJustOne = true,
  didFetchFailMustBeTrueForAllRatherThanForJustOne = false,
}) {
  const isQueuedValues = fetchStatusObjects.filter(o => has_(o, 'isQueued')).map(o => o.isQueued)
  const isQueuedFunc = isQueuedMustBeTrueForAllRatherThanForJustOne ? every_ : some_
  const isQueued = isQueuedFunc(isQueuedValues)

  const hasFetchBeenAttemptedValues = fetchStatusObjects.filter(o => has_(o, 'hasFetchBeenAttempted')).map(o => o.hasFetchBeenAttempted)
  const hasFetchBeenAttemptedFunc = hasFetchBeenAttemptedMustBeTrueForAllRatherThanForJustOne ? every_ : some_
  const hasFetchBeenAttempted = hasFetchBeenAttemptedFunc(hasFetchBeenAttemptedValues)

  const isFetchingValues = fetchStatusObjects.filter(o => has_(o, 'isFetching')).map(o => o.isFetching)
  const isFetchingFunc = isFetchingMustBeTrueForAllRatherThanForJustOne ? every_ : some_
  const isFetching = isFetchingFunc(isFetchingValues)

  const didFetchSucceedValues = fetchStatusObjects.filter(o => has_(o, 'didFetchSucceed')).map(o => o.didFetchSucceed)
  const didFetchSucceedFunc = didFetchSucceedMustBeTrueForAllRatherThanForJustOne ? every_ : some_
  const didFetchSucceed = didFetchSucceedFunc(didFetchSucceedValues)

  const didFetchFailValues = fetchStatusObjects.filter(o => has_(o, 'didFetchFail')).map(o => o.didFetchFail)
  const didFetchFailFunc = didFetchFailMustBeTrueForAllRatherThanForJustOne ? every_ : some_
  const didFetchFail = didFetchFailFunc(didFetchFailValues)


  return {
    isQueued,
    hasFetchBeenAttempted,
    isFetching,
    didFetchSucceed,
    didFetchFail,
  }
}


/*
 * *****************************************************************************
 * Working with arrays
 * *****************************************************************************
*/

/**
 * shallow compare only
 * https://gist.github.com/telekosmos/3b62a31a5c43f40849bb
 */
export const deDupeArray = arrArg => (
  arrArg.filter((elem, pos, arr) => (
    arr.indexOf(elem) === pos
  ))
)

export const doTwoArraysContainTheSameItems = (array1, array2, orderMatters) => {
  if (array1.length !== array2.length) { return false }

  if (orderMatters) {
    return array1.every((_, index) => (array1[index] === array2[index]))
  }
  // if order doesn't matter
  return array1.every(array1Item => (
    array2.includes(array1Item)
  ))
}

/**
 * const a1 = [
 *   ['1111', '2222'],
 *   ['3333', '4444'],
 *   ['5555', '4444'],
 * ]
 *
 * const a2 = [
 *   ['4444', '3333'],
 *   ['5555', '4444'],
 * ]
 *
 *
 * f([a1, a2], orderMatters=true)
 * ->
 * [
 *   ['1111', '2222'],
 *   ['3333', '4444'],
 *   ['5555', '4444'],
 *   ['4444', '3333']
 * ]
 *
 * f([a1, a2], orderMatters=false)
 * ->
 * [
 *   ['1111', '2222'],
 *   ['3333', '4444'],
 *   ['5555', '4444'],
 * ]
 *
 */
export const filterSubarrayDuplicatesOfNestedArrays = (nestedArrays, orderMatters = false) => {
  let finalNestedArray = []
  nestedArrays.forEach(nestedArray => {
    const notYetInFinalNestedArray = nestedArray.filter(subArray => (
      !finalNestedArray.some(finalSubArray => (
        doTwoArraysContainTheSameItems(finalSubArray, subArray, orderMatters)
      ))
    ))
    finalNestedArray = [...finalNestedArray, ...notYetInFinalNestedArray]
  })
  return finalNestedArray
}

/**
 * Sorts an array according to how a template array is sorted. If items appear
 * in the array that aren't in the template array, those items are put at the
 * end of the returned array in alphabetical order.
 */
export const sortArrayByTemplateArray = (
  arr,
  templateArr,
  // What if the array you want to sort is an array of objects whose 'coolKey'
  // prop is what you want to sort by? set this optional argument to elem =>
  // elem.coolKey
  itemGetter=elem => elem,
) => (
  sortBy_(
    arr,
    [
      // +1 because 0 is falsy, and we only want to get to the 'Or' clause if
      // the item is not in the array, not if the item is the first element in
      // the template array
      elem => (templateArr.indexOf(itemGetter(elem)) + 1 || templateArr.length + 1),
      // The previous line puts all items at the end of the array that were not
      // found in the template array. Sort them alphabetically.
      elem => elem,
    ],
  )
)


/**
 * Similar to sortArrayByTemplateArray except that an array of objects can be
 * passed in whose common key is compared against the template array. Like
 * sortArrayByTemplateArray, if items appear in the array whose target prop
 * isn't the template array, those items are put at the end of the returned
 * array in alphabetical order by target prop name.
 */
export const sortArrayOfObjectsByTemplateArray = (arr, templateArraySortKey, templateArr) => (
  sortBy_(
    arr,
    [
      o => (
        templateArr.indexOf(
          // +1 because 0 is falsy, and we only want to get to the 'Or' clause if
          // the item is not in the array, not if the item is the first element in
          // the template array
          o[templateArraySortKey]) + 1
        ||
        // If the item is not in the template array, put it at the end
        templateArr.length + 1
      ),
      // The previous lines put all items at the end of the array that were not
      // found in the template array. Sort them alphabetically by target prop
      // name.
      o => o[templateArraySortKey],
    ],
  )
)

/**
 * Similar to sortArrayOfObjectsByTemplateArray except that after the array is
 * sorted by template array, you can then sort each "section" of the array
 * alphabetically on a different object key.
 */
export const sortArrayOfObjectsByTemplateArrayThenAlphabetically = (
  arr,
  templateArraySortKey,
  templateArr,
  alphabeticalSortKey,
) => (
  sortBy_(
    arr,
    [
      o => (
        templateArr.indexOf(
          // +1 because 0 is falsy, and we only want to get to the 'Or' clause
          // if the item is not in the array, not if the item is the first
          // element in the template array
          o[templateArraySortKey]) + 1
        ||
        // If the item is not in the template array, put it at the end
        templateArr.length + 1
      ),
      // The previous lines put all items at the end of the array that were not
      // found in the template array. Sort them alphabetically by target prop
      // name.
      o => o[templateArraySortKey],
      alphabeticalSortKey,
    ],
  )
)

// Takes an array of strings and numbers and returns a single string:
// ['a', 4, 'b'] -> 'a[4].b'
// [4, 'a', 'b'] -> '[4].a.b'
// ['a', 'b', 4] -> 'a.b[4]'
// ['a', 'b', '4'] -> 'a.b.4'
function formatNestedPathArraySuitableForLodash(arr) {
  return arr.reduce(
    (acc, e, index) => {
      if (index === 0) {
        return isNumber_(e) ? `[${e}]` : e
      }
      return isNumber_(e) ? `${acc}[e]` : `${acc}.${e}`
    },
    '',
  )
}

/**
 * Sorts an array by a date string found somewhere in each item. The array items
 * can be arrays themselves, or objects with nested properties. For instance,
 * it can look like this:
 *
 * const arr = [
 *   ['bla', '09/25/2017'],
 *   ['taa', '05/10/2017'],
 *   ['ska', '08/08/2017'],
 *   ['laa', '06/26/2017'],
 *   ['cha', '07/23/2017'],
 * ]
 *
 * or like this:
 *
 * const arr3 = [
 *   {shtack: 'blah', pack: {dunkirk: 5, lawh: ['pi', '09/25/2017']}},
 *   {shtack: 'blah', pack: {dunkirk: 1, lawh: ['pi', '05/10/2017']}},
 *   {shtack: 'blah', pack: {dunkirk: 4, lawh: ['pi', '08/08/2017']}},
 *   {shtack: 'blah', pack: {dunkirk: 2, lawh: ['pi', '06/26/2017']}},
 *   {shtack: 'blah', pack: {dunkirk: 3, lawh: ['pi', '07/23/2017']}},
 * ]
 *
 * or any other shape.
 *
 */
export const sortByDateString = ({
  arr,
  nestedPath,
  dateFormat,
  ascending = true,
  // The default behavior is to throw an error if any date string is not in the
  // supplied format, but you can instead decide to sort improperly-formatted
  // strings A-Z at the end of the array.
  sortNonDateStringsAToZInsteadOfThrowingError = true,
}) => {
  const objsWithProperlyFormattedDateStrings = []
  const objsWithImproperlyFormattedStrings = []
  arr.forEach(o => {
    const dateAsMoment = moment(drillDownInObject(o, ...nestedPath), dateFormat)
    if (dateAsMoment.isValid()) {
      objsWithProperlyFormattedDateStrings.push(o)
    } else {
      objsWithImproperlyFormattedStrings.push(o)
    }
  })
  if (
    !sortNonDateStringsAToZInsteadOfThrowingError
    && isTruthyAndNonEmpty(objsWithImproperlyFormattedStrings)
  ) {
    throw new Error(`${objsWithImproperlyFormattedStrings.length} items in the arr passed in have an improperly formatted date string in the ${JSON.stringify(nestedPath)} field (format must be ${dateFormat}). arr:\n${JSON.stringify(arr, undefined, 2)}`)
  }

  const sortedObjsWithProperlyFormattedDateStrings = objsWithProperlyFormattedDateStrings.sort((above, below) => {
    const a = moment(drillDownInObject(above, ...nestedPath), dateFormat)
    const b = moment(drillDownInObject(below, ...nestedPath), dateFormat)
    if (a < b) { return ascending ? -1 : 1 }
    if (a === b) { return 0 }
    return ascending ? 1 : -1
  })
  const sortedObjsWithImproperlyFormattedStrings = orderBy_(
    objsWithImproperlyFormattedStrings,
    [formatNestedPathArraySuitableForLodash(nestedPath)],
    [ascending ? 'asc' : 'desc'],
  )

  return ascending
    ? [
      ...sortedObjsWithImproperlyFormattedStrings,
      ...sortedObjsWithProperlyFormattedDateStrings,
    ]
    : [
      ...sortedObjsWithProperlyFormattedDateStrings,
      ...sortedObjsWithImproperlyFormattedStrings,
    ]
}

// Like sortByDateString except that the field does not have to be a date string
// but can rather be any of the date types returned by the API. This function
// will determine which type the date field is and sort accordingly.
export const sortByApiDate = ({
  arr,
  nestedPath,
  descending = true,
}) => (
  orderBy_(
    arr,
    [o => convertApiDateToMoment(drillDownInObject(o, ...nestedPath))],
    descending ? ['desc'] : [],
  )
)

/**
 * A version of Javascript's Array.prototype.includes() function which tests
 * multiple values rather than a single one. Repeats are ignored; that is, if
 * subsetArray has two of the same value and testArray has only one, the
 * function returns true.
 */
export const includesAll = (testArray, subsetArray) => (
  subsetArray.every(val => testArray.indexOf(val) !== -1)
)

/**
 * A version of Javascript's Array.prototype.includes() function which tests
 * multiple values rather than a single one.
 */
export const includesSome = (testArray, subsetArray) => (
  subsetArray.some(val => testArray.indexOf(val) !== -1)
)

/**
 * Pass in multiple arrays and this function returns an array of only those
 * items found in every array passed in.
 */
export const filterInAllArraysOnly = (...arrays) => {
  if (arrays.length === 1) { return arrays[0] }

  const firstArray = arrays[0]
  const allOtherArrays = arrays.slice(1)
  return firstArray.filter(el => allOtherArrays.every(otherArray => otherArray.includes(el)))
}

/**
 * Oftentimes we get an array of information from the API that we need to save
 * to the Redux store. But we don't want to save the array itself; rather we
 * want to convert the array into an object with meaningful keys (the big
 * benefit of this is that things are easier to look up by id and that our store
 * shape stays consistent, allowing us to write higher-order selectors that
 * target objects but that don't work with arrays). For instance, addresses are
 * received from the API as an array:
 *
 * [
 *   { id: 'a1234', city: 'Belmont', ... },
 *   { id: 'a1235', city: 'Austin', ... },
 *   ...
 * ]
 *
 * We want to convert this before saving to the Redux store:
 *
 * {
 *   a1234: { id: 'a1234', city: 'Belmont', ... },
 *   a1235: { id: 'a1235', city: 'Austin', ... },
 * }
 *
 * This function performs this conversion.
 */
export const convertArrOfObjsOrSingleObjToObjOfSubObjs = (entity, key = 'id') => {
  if (Array.isArray(entity)) {
    return entity.reduce((acc, subobject) => ({
      [subobject[key]]: subobject,
      ...acc,
    }), {})
  }
  return { [entity[key]]: entity }
}


export const convertArrayOfObjectsToObjectOfSubObjects = (arr, propNameOfSubObjectsToUseAsKey) => (
  arr.reduce((acc, obj) => ({
    ...acc,
    [obj[propNameOfSubObjectsToUseAsKey]]: obj,
  }), {})
)


// Sums the elements at the same index in array of arrays into a single array.
// Treats null/undefined elements as 0. https://stackoverflow.com/a/36939600
export const sumElementsAtSameIndexInArrayOfArrays = arrayOfArrays => (
  arrayOfArrays[0].map((_a, i) => arrayOfArrays.reduce((p, _b, j) => p + (arrayOfArrays[j][i] || 0), 0))
)


// https://github.com/lodash/lodash/issues/1743#issuecomment-170598139
export const getAreAllElementsOfOneArrayIncludedInOtherArray = (subArray, fullArray) => (
  difference_(subArray, fullArray).length === 0
)


/*
 * *****************************************************************************
 * Working with strings
 * *****************************************************************************
*/

// https://stackoverflow.com/a/1026087
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// https://stackoverflow.com/a/175787. This doesn't work with hexadecimal or
// anything beyond base 10.
export function isNumberOrStringRepresentationOfNumber(val) {
  return !isNaN(Number(val))
}


/**
 * f('one and a half') --> 'one-and-a-half'
 * f('one and a half', 'two') --> 'one-and-a-half-two'
 * f('one and a half', 'two thirds') --> 'one-and-a-half-two-thirds'
 */
export function createFormFieldName(...strs) {
  return strs.map(str => (
    str
      .toLowerCase()
      .replace(/ /g, '-') // replaces spaces with hyphen
  )).join('-')
}

/**
 * Inspired by https://github.com/kahwee/truncate-middle/blob/master/index.js.
 * The difference is that there's no frontLen and backLen args, only a len arg,
 * and the ellipsis is placed in the middle of the string. Also, no truncateStr
 * (it's always an ellipsis)
 */
export function truncateStringInMiddle(str, len) {
  if (str === null) {
    return ''
  }
  const strLen = str.length
  // Setting default values
  if (len === 0 || len >= strLen || strLen < 5) {
    return str
  }
  let lenToUse = len
  // The returned string can't be less than 5 characters long: 1 char start, 3
  // chars ellipsis, 1 char end
  if (len < 5) { lenToUse = 5 }


  return `${str.slice(0, Math.floor((lenToUse - 3) / 2))}...${str.slice(strLen - Math.ceil((lenToUse - 3) / 2))}`
}

// https://stackoverflow.com/a/6623263
export function removeWhitespace(str) {
  return str.replace(/ /g, '')
}

// https://stackoverflow.com/a/7924240. This isn't the highest-voted answer
// stackoverflow answer, but the highest-voted answer would require escaping the
// metacharacters of the substring, as this comment highlights
// https://stackoverflow.com/questions/4009756#comment22628790_4009768.
// This one works out of the box, copy-paste.
export function occurrencesOfSubstring(string, subString, allowOverlapping) {
  /* eslint-disable no-param-reassign, no-constant-condition, no-plusplus */
  string += ''
  subString += ''
  if (subString.length <= 0) return (string.length + 1)

  let n = 0
  let pos = 0
  const step = allowOverlapping ? 1 : subString.length

  while (true) {
    pos = string.indexOf(subString, pos)
    if (pos >= 0) {
      ++n
      pos += step
    } else break
  }
  return n
  /* eslint-enable no-param-reassign, no-constant-condition, no-plusplus */
}

export function formatPhoneNumber(phoneNum) {
  // US. phone number
  if (
    phoneNum.length === 10 ||
    (phoneNum.length === 11 && phoneNum[0] === '1') ||
    (phoneNum.length === 12 && phoneNum[0] === '+')
  ) {
    return formatPhoneNumberAsTenDigitUSPhoneNumber(phoneNum)
  }
  // This must be an international phone number, in which case don't format it
  // at all
  return phoneNum
}

// Returns (123) 456-7890. Only the _last_ 10 digits of phoneNum are used, that
// way you can pass in something like .
function formatPhoneNumberAsTenDigitUSPhoneNumber(phoneNum) {
  const last4Digits = phoneNum.slice(-4)
  const threeDigitsAfterAreaCode = phoneNum.slice(-7, -4)
  const areaCode = phoneNum.slice(-10, -7)
  return `(${areaCode}) ${threeDigitsAfterAreaCode}-${last4Digits}`
}

// The phone number passed in should be E164 format (if it isn't, the string
// will be returned just as it was passed in). If it's a US phone number
// (technically, if it's a phone number whose country code is +1, which includes
// countries like Canada), it will be formatted as '(303) 123-4567. Otherwise,
// it will be formatted as an international number, e.g. '+44 55 4433 454'.
export function formatPhoneNumberForDisplay(s) {
  const phoneNumber = parsePhoneNumber(s)
  if (!phoneNumber) {
    return s
  }
  if (COUNTRIES_WHOSE_CALLING_CODE_IS_ONE.includes(phoneNumber.country)) {
    return phoneNumber.formatNational()
  }
  return phoneNumber.formatInternational()
}

export function formatPhoneNumberAndPotentialExtensionForDisplay(phoneNum, ext) {
  const phoneNumber = formatPhoneNumberForDisplay(phoneNum)
  const extension = ext ? ` ext. ${ext}` : ''
  return `${phoneNumber}${extension}`
}

export function getIsPossiblePhoneNumber(
  val,
  blankOk = true,
) {
  if (blankOk) {
    if (!val || val.trim() === '') {
      return true
    }
  }
  const phoneNumber = parsePhoneNumber(val)
  return (
    // When we "validate" a phone number, all we want to do is check
    // whether it has the right number of digits (e.g. 10 digits for a
    // USA number, not including the country code). We don't want to
    // check anything else, e.g. we don't want to check whether its area
    // code is valid. Therefore we use libphonenumber-js's isPossible(),
    // which only checks for correct length, rather than its isValid()
    // method. Read about the motivation here:
    // https://github.com/catamphetamine/libphonenumber-js#using-phone-number-validation-feature
    phoneNumber && phoneNumber.isPossible()
  )
}


// Pass in a string, object, array, boolean, or falsey value and this will tell
// you whether the string exists anywhere in the object. For objects, all values
// are checked recursively (keys aren't checked by default, but you can pass in
// a flag to check them). For arrays, all items are checked recursively (so
// arrays within arrays and objects within arrays work fine). Great for HTTP
// error messages and response bodies (for instance, an error response body may
// have a "message" prop or an "errorMessage" prop or a "messageDetail" prop or
// any combination of these). NOTE: this will always return false if the object
// passed in is falsy, except for the number 0 (if obj=0 and str='0', true will
// be returned). For example, if obj=undefined and str='undefined', this will
// return false (falsy values aren't converted to their string equivalents).
export function doesAnyObjectContainString({
  obj,
  str,
  caseSensitive = false,
  checkObjectKeys = false,
}) {
  if (obj === 0 && str === '0') { return true }
  if (!obj) { return false }
  let toSearch
  if (isNumber_(obj)) { toSearch = obj.toString() }
  if (isString_(obj)) { toSearch = obj }
  if (isPlainObject_(obj)) {
    /* eslint-disable max-len */
    const doAnyOfTheObjValuesContainString = valuesFp_(obj).some(value => doesAnyObjectContainString({ obj: value, str, caseSensitive, checkObjectKeys }))
    if (!checkObjectKeys) { return doAnyOfTheObjValuesContainString }
    const doAnyOfTheObjKeysContainString = keysFp_(obj).some(value => doesAnyObjectContainString({ obj: value, str, caseSensitive, checkObjectKeys }))
    return doAnyOfTheObjValuesContainString || doAnyOfTheObjKeysContainString
    /* eslint-enable max-len */
  }
  if (isArray_(obj)) {
    return obj.some(item => doesAnyObjectContainString({ obj: item, str, caseSensitive, checkObjectKeys }))
  }
  if (isFunction_(obj)) { return false }
  if (caseSensitive) {
    return toSearch.includes(str)
  }
  toSearch = toSearch.toLowerCase()
  const strLowerCase = str.toLowerCase()
  return toSearch.includes(strLowerCase)
}


export function getAreAllDigitsInStringASpecificDigit({ str, digit }) {
  return [...str].every(c => {
    if (isNumberOrStringRepresentationOfNumber(c)) {
      return c === digit.toString()
    }
    return true
  })
}

export function recursivelyReplaceSubstringInAllStringsInObject({
  obj,
  substring,
  replacement,
  caseSensitive = false,
  checkObjectKeys = false,
}) {
  if (isString_(obj)) {
    const regex = new RegExp(substring, caseSensitive ? 'g' : 'gi')
    return obj.replace(regex, replacement)
  } else if (isPlainObject_(obj)) {
    return flow_(
      entriesFp_,
      reduceFp_(
        (acc, [key, value]) => {
          const newKey = checkObjectKeys
            ? recursivelyReplaceSubstringInAllStringsInObject({
              obj: key,
              substring,
              replacement,
              caseSensitive,
              checkObjectKeys,
            })
            : key
          const newValue = recursivelyReplaceSubstringInAllStringsInObject({
            obj: value,
            substring,
            replacement,
            caseSensitive,
            checkObjectKeys,
          })
          return { ...acc, [newKey]: newValue }
        },
        {},
      ),
    )(obj)
  } else if (isArray_(obj)) {
    return obj.map(item => recursivelyReplaceSubstringInAllStringsInObject({
      obj: item,
      substring,
      replacement,
      caseSensitive,
      checkObjectKeys,
    }))
  }
  // if obj is undefined, a number, a function, etc
  return obj
}


// Based on https://stackoverflow.com/a/11799630.
export function getIsStringRepresentationOfMoney({
  str,
  // If true, these values are allowed:
  // '0'
  // '0.00'
  // '.00'
  // '0.0000000' (only if okToHaveMoreThanTwoDigitsAfterDecimal is true)
  // '0.0' (only if okToHaveOnlyOneDigitAfterDecimal is true)
  // '0.' (only if okToHaveDecimalBeTheLastChar is true)
  // '.' (only if okToHaveDecimalBeTheLastChar is true)
  // '.0' (only if okToHaveOnlyOneDigitAfterDecimal is true)
  // '.0000000' (only if okToHaveMoreThanTwoDigitsAfterDecimal is true)
  zeroAllowed = true,
  // Negative value must come after the dollar sign
  negativeValuesAllowed = true,
  dollarSignAtBeginningAllowed = true,
  dollarSignAtBeginningRequired = false,
  commasAllowedIfTheyreInTheRightPlaces = true,
  okToHaveDecimalBeTheLastChar = false,
  okToHaveOnlyOneDigitAfterDecimal = false,
  okToHaveMoreThanTwoDigitsAfterDecimal = false,
}) {
  if (!isString_(str)) { return false }

  // zeroes
  if (zeroAllowed) {
    const areTheseZeroValuesOk = {
      // eslint-disable-next-line quote-props
      '0': true,
      '0.00': true,
      '.00': true,
      '0.0': okToHaveOnlyOneDigitAfterDecimal,
      '0.': okToHaveDecimalBeTheLastChar,
      '.': okToHaveDecimalBeTheLastChar,
      '.0': okToHaveOnlyOneDigitAfterDecimal,
    }

    if (keys_(areTheseZeroValuesOk).includes(str)) {
      return areTheseZeroValuesOk[str]
    }

    if (
      // an optional zero followed by a . then 3 or more zeroes
      str.search(/^(0)?\.\d{3,}$/) >= 0
    ) {
      return okToHaveMoreThanTwoDigitsAfterDecimal
    }
  }

  // Dollar Signs
  let dollarSignRegex
  if (dollarSignAtBeginningAllowed) {
    dollarSignRegex = '\\$?' // optional dollar sign
  } else {
    dollarSignRegex = ''
  }
  if (dollarSignAtBeginningRequired) {
    dollarSignRegex = '\\$'
  }

  // Negative values
  const negativeValuesRegex = negativeValuesAllowed
    ? '-?'
    : ''

  // digits before the decimal
  const mantissaValuesRegex = commasAllowedIfTheyreInTheRightPlaces
    ? [
      '(', // start of the 'or' expression
      // One or two digits, followed by either nothing or groups of [1 comma and
      // 3 digits]
      '(\\d+(,\\d{3})*)',
      // or
      '|',
      // just digits
      '(\\d+)',
      ')', // end of the 'or' expression
    ].join('')
    : '\\d+' // just digits

  // decimal and digits after
  let decimalAndDecimalValuesRegex
  if (!okToHaveOnlyOneDigitAfterDecimal && !okToHaveMoreThanTwoDigitsAfterDecimal) {
    // either a dot with exactly 2 digits after, or nothing
    decimalAndDecimalValuesRegex = '(\\.\\d{2})?'
  } else if (okToHaveOnlyOneDigitAfterDecimal && !okToHaveMoreThanTwoDigitsAfterDecimal) {
    // either a dot with either 1 or 2 digits after, or nothing
    decimalAndDecimalValuesRegex = '(\\.\\d{1,2})?'
  } else if (okToHaveOnlyOneDigitAfterDecimal && okToHaveMoreThanTwoDigitsAfterDecimal) {
    // either a dot with one or more digits after, or nothing
    decimalAndDecimalValuesRegex = '(\\.\\d+)?'
  }
  if (okToHaveDecimalBeTheLastChar) {
    decimalAndDecimalValuesRegex = `((\\.)|(${decimalAndDecimalValuesRegex}))`
  }

  const moneyRegex = new RegExp([
    '^', // start of string
    dollarSignRegex,
    negativeValuesRegex,
    mantissaValuesRegex,
    decimalAndDecimalValuesRegex,
    '$', // end of string
  ].join(''))
  return moneyRegex.test(str)
}


// https://stackoverflow.com/a/11799630. See unit tests for examples. Use
// getIsStringRepresentationOfMoney() to determine whether the input is valid
// for this function.
export function convertStringRepresentationOfUSAMoneyToNumber({ str }) {
  if (isNumber_(str)) { return str }
  if (str === '.') { return 0 }
  return Number(str.replace(/[$,]/g, ''))
}


// These all return '$12,345.68':
// 12345.6789
// '12345.6789'
// '$12,345.6789'
// '12,345.6789'
//
export function getCurrencyFormatter(currency) {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  })
}

// Returns undefined if value can't be interpreted as a money value.
export function formatStringOrNumberToMoney({
  value,
  includeSign,
  currency='USD' }) {
  let num
  const currencySymbol = getSymbolFromCurrency(currency)
  if (isNumberOrStringRepresentationOfNumber(value)) {
    num = Number(value)
  } else if (getIsStringRepresentationOfMoney({
    str: value,
    zeroAllowed: true,
    negativeValuesAllowed: true,
    dollarSignAtBeginningAllowed: true,
    dollarSignAtBeginningRequired: false,
    commasAllowedIfTheyreInTheRightPlaces: true,
    okToHaveDecimalBeTheLastChar: true,
    okToHaveOnlyOneDigitAfterDecimal: true,
    okToHaveMoreThanTwoDigitsAfterDecimal: true,
  })) {
    num = convertStringRepresentationOfUSAMoneyToNumber({ str: value })
  } else {
    return undefined
  }

  const currencyFormatter = getCurrencyFormatter(currency)
  const formattedCurrency = currencyFormatter.format(num)
  // const amountString = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  if (num < 0) {
    return includeSign
      ? formattedCurrency
      : formattedCurrency.replace(currencySymbol, '')
  }
  return includeSign
    ? formattedCurrency
    : formattedCurrency.replace(currencySymbol, '')
}


// this, that and other
// this, that or other
export function formatArrayOfStringsAsNaturalLanguagePhrase(
  a,
  orRatherThanAnd = false,
) {
  if (a.length === 0) { return '' }
  if (a.length === 1) { return a[0].toString() }
  const allButLast = a.slice(0, -1).join(', ')
  return `${allButLast} ${orRatherThanAnd ? 'or' : 'and'} ${a[a.length - 1]}`
}

// https://dev-tap.microstarlogistics.com/this/is/ignored -> ['dev-tap']
// http://some.nonsense.here.a.t.w.com -> ['some', 'nonsense', 'here', 'a', 't']
// what.the.puke.com/shpa/til -> ['what', 'the']
// https://aUrlWithNoSubdomains.com -> []
// a/string/with/no/periods -> []
export function getAllSubdomainsOfUrl(url) {
  if (!url.includes('.')) { return [] }
  const protocolAndDomain = url.split('://')
  const domain = protocolAndDomain[protocolAndDomain.length - 1]
  const subdomainsAndDomainAndTopLevelDomain = domain.split('.')
  const subdomains = subdomainsAndDomainAndTopLevelDomain.slice(0, subdomainsAndDomainAndTopLevelDomain.length - 2)
  return subdomains
}

export function prependStringToAllObjectKeys({ obj, str }) {
  return Object.keys(obj).reduce(
    (acc, key) => {
      const newKey = `${str}${key}`
      return { ...acc, [newKey]: obj[key] }
    },
    {},
  )
}


// https://stackoverflow.com/a/2117523
export function createUuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export function replaceAllUuidsInString(str, subStrToReplaceWith) {
  // https://stackoverflow.com/a/13653180
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/ig
  return str.replace(uuidRegex, subStrToReplaceWith)
}

// https://stackoverflow.com/a/19156197
export function trimQuotationMarksFromStartAndEndOfString(s) {
  return s.replace(/^["'](.+(?=["']$))["']$/, '$1')
}

// Hundreds of TAP customer names have leading and trailing quotes, e.g.
//
// "KC: O'Malley Beverage, Inc."
// rather than
// KC: O'Malley Beverage, Inc.
//
// This function strips such quotation marks, even if the string has no business
// unit prepended to it.
export function removeBusinessUnitPrependedToCustomerName({
  name,
}) {
  // Potential prepends are all business unit IDs--MKM, KC, etc--and the
  // human-readable name--MicroStar, KegCraft, etc.
  const potentialPrepends = Object.keys(BUSINESS_UNIT_ID_TO_NAME_MAP).reduce(
    (acc, busUnitId) => ([
      ...acc,
      busUnitId,
      BUSINESS_UNIT_ID_TO_NAME_MAP[busUnitId],
    ]),
    [],
  )
  const block = potentialPrepends.join('|')
  // Why not put this regex onto multiple lines with comments so it's easier to
  // read? Because it stops working when I try to. Don't know why.
  const rx = RegExp(`^['"]*(${block})\\s*[-–:—]+\\s*(.+)['"]*$`, 'i')

  const match = name.match(rx)
  if (match) {
    return match[2]
  }
  return trimQuotationMarksFromStartAndEndOfString(name)
}


// https://stackoverflow.com/a/49731453
export function getMostFrequentItemInArray(a) {
  return flow_(
    countBy_,
    entries_,
    partialRight_(maxBy_, last_),
    head_,
  )(a)
}


/*
 * *****************************************************************************
 * Working with dates
 * *****************************************************************************
*/

// CODE_COMMENTS_243: a modification of
// https://stackoverflow.com/a/9893752/6995996.
function getLocaleDateString() {
  const formats = {
    'ar-SA': 'DD/MM/YY',
    'bg-BG': 'DD.M.YYYY',
    'ca-ES': 'DD/MM/YYYY',
    'zh-TW': 'YYYY/M/D',
    'cs-CZ': 'D.M.YYYY',
    'da-DK': 'DD-MM-YYYY',
    'de-DE': 'DD.MM.YYYY',
    'el-GR': 'D/M/YYYY',
    'en-US': 'M/D/YYYY',
    'fi-FI': 'D.M.YYYY',
    'fr-FR': 'DD/MM/YYYY',
    'he-IL': 'DD/MM/YYYY',
    'hu-HU': 'YYYY. MM. DD.',
    'is-IS': 'D.M.YYYY',
    'it-IT': 'DD/MM/YYYY',
    'ja-JP': 'YYYY/MM/DD',
    'ko-KR': 'YYYY-MM-DD',
    'nl-NL': 'D-M-YYYY',
    'nb-NO': 'DD.MM.YYYY',
    'pl-PL': 'YYYY-MM-DD',
    'pt-BR': 'D/M/YYYY',
    'ro-RO': 'DD.MM.YYYY',
    'ru-RU': 'DD.MM.YYYY',
    'hr-HR': 'D.M.YYYY',
    'sk-SK': 'D. M. YYYY',
    'sq-AL': 'YYYY-MM-DD',
    'sv-SE': 'YYYY-MM-DD',
    'th-TH': 'D/M/YYYY',
    'tr-TR': 'DD.MM.YYYY',
    'ur-PK': 'DD/MM/YYYY',
    'id-ID': 'DD/MM/YYYY',
    'uk-UA': 'DD.MM.YYYY',
    'be-BY': 'DD.MM.YYYY',
    'sl-SI': 'D.M.YYYY',
    'et-EE': 'D.MM.YYYY',
    'lv-LV': 'YYYY.MM.DD.',
    'lt-LT': 'YYYY.MM.DD',
    'fa-IR': 'MM/DD/YYYY',
    'vi-VN': 'DD/MM/YYYY',
    'hy-AM': 'DD.MM.YYYY',
    'az-Latn-AZ': 'DD.MM.YYYY',
    'eu-ES': 'YYYY/MM/DD',
    'mk-MK': 'DD.MM.YYYY',
    'af-ZA': 'YYYY/MM/DD',
    'ka-GE': 'DD.MM.YYYY',
    'fo-FO': 'DD-MM-YYYY',
    'hi-IN': 'DD-MM-YYYY',
    'ms-MY': 'DD/MM/YYYY',
    'kk-KZ': 'DD.MM.YYYY',
    'ky-KG': 'DD.MM.YY',
    'sw-KE': 'M/D/YYYY',
    'uz-Latn-UZ': 'DD/MM YYYY',
    'tt-RU': 'DD.MM.YYYY',
    'pa-IN': 'DD-MM-YY',
    'gu-IN': 'DD-MM-YY',
    'ta-IN': 'DD-MM-YYYY',
    'te-IN': 'DD-MM-YY',
    'kn-IN': 'DD-MM-YY',
    'mr-IN': 'DD-MM-YYYY',
    'sa-IN': 'DD-MM-YYYY',
    'mn-MN': 'YY.MM.DD',
    'gl-ES': 'DD/MM/YY',
    'kok-IN': 'DD-MM-YYYY',
    'syr-SY': 'DD/MM/YYYY',
    'dv-MV': 'DD/MM/YY',
    'ar-IQ': 'DD/MM/YYYY',
    'zh-CN': 'YYYY/M/D',
    'de-CH': 'DD.MM.YYYY',
    'en-GB': 'DD/MM/YYYY',
    'es-MX': 'DD/MM/YYYY',
    'fr-BE': 'D/MM/YYYY',
    'it-CH': 'DD.MM.YYYY',
    'nl-BE': 'D/MM/YYYY',
    'nn-NO': 'DD.MM.YYYY',
    'pt-PT': 'DD-MM-YYYY',
    'sr-Latn-CS': 'D.M.YYYY',
    'sv-FI': 'D.M.YYYY',
    'az-Cyrl-AZ': 'DD.MM.YYYY',
    'ms-BN': 'DD/MM/YYYY',
    'uz-Cyrl-UZ': 'DD.MM.YYYY',
    'ar-EG': 'DD/MM/YYYY',
    'zh-HK': 'D/M/YYYY',
    'de-AT': 'DD.MM.YYYY',
    'en-AU': 'D/MM/YYYY',
    'es-ES': 'DD/MM/YYYY',
    'fr-CA': 'YYYY-MM-DD',
    'sr-Cyrl-CS': 'D.M.YYYY',
    'ar-LY': 'DD/MM/YYYY',
    'zh-SG': 'D/M/YYYY',
    'de-LU': 'DD.MM.YYYY',
    'en-CA': 'DD/MM/YYYY',
    'es-GT': 'DD/MM/YYYY',
    'fr-CH': 'DD.MM.YYYY',
    'ar-DZ': 'DD-MM-YYYY',
    'zh-MO': 'D/M/YYYY',
    'de-LI': 'DD.MM.YYYY',
    'en-NZ': 'D/MM/YYYY',
    'es-CR': 'DD/MM/YYYY',
    'fr-LU': 'DD/MM/YYYY',
    'ar-MA': 'DD-MM-YYYY',
    'en-IE': 'DD/MM/YYYY',
    'es-PA': 'MM/DD/YYYY',
    'fr-MC': 'DD/MM/YYYY',
    'ar-TN': 'DD-MM-YYYY',
    'en-ZA': 'YYYY/MM/DD',
    'es-DO': 'DD/MM/YYYY',
    'ar-OM': 'DD/MM/YYYY',
    'en-JM': 'DD/MM/YYYY',
    'es-VE': 'DD/MM/YYYY',
    'ar-YE': 'DD/MM/YYYY',
    'en-029': 'MM/DD/YYYY',
    'es-CO': 'DD/MM/YYYY',
    'ar-SY': 'DD/MM/YYYY',
    'en-BZ': 'DD/MM/YYYY',
    'es-PE': 'DD/MM/YYYY',
    'ar-JO': 'DD/MM/YYYY',
    'en-TT': 'DD/MM/YYYY',
    'es-AR': 'DD/MM/YYYY',
    'ar-LB': 'DD/MM/YYYY',
    'en-ZW': 'M/D/YYYY',
    'es-EC': 'DD/MM/YYYY',
    'ar-KW': 'DD/MM/YYYY',
    'en-PH': 'M/D/YYYY',
    'es-CL': 'DD-MM-YYYY',
    'ar-AE': 'DD/MM/YYYY',
    'es-UY': 'DD/MM/YYYY',
    'ar-BH': 'DD/MM/YYYY',
    'es-PY': 'DD/MM/YYYY',
    'ar-QA': 'DD/MM/YYYY',
    'es-BO': 'DD/MM/YYYY',
    'es-SV': 'DD/MM/YYYY',
    'es-HN': 'DD/MM/YYYY',
    'es-NI': 'DD/MM/YYYY',
    'es-PR': 'DD/MM/YYYY',
    'am-ET': 'D/M/YYYY',
    'tzm-Latn-DZ': 'DD-MM-YYYY',
    'iu-Latn-CA': 'D/MM/YYYY',
    'sma-NO': 'DD.MM.YYYY',
    'mn-Mong-CN': 'YYYY/M/D',
    'gd-GB': 'DD/MM/YYYY',
    'en-MY': 'D/M/YYYY',
    'prs-AF': 'DD/MM/YY',
    'bn-BD': 'DD-MM-YY',
    'wo-SN': 'DD/MM/YYYY',
    'rw-RW': 'M/D/YYYY',
    'qut-GT': 'DD/MM/YYYY',
    'sah-RU': 'MM.DD.YYYY',
    'gsw-FR': 'DD/MM/YYYY',
    'co-FR': 'DD/MM/YYYY',
    'oc-FR': 'DD/MM/YYYY',
    'mi-NZ': 'DD/MM/YYYY',
    'ga-IE': 'DD/MM/YYYY',
    'se-SE': 'YYYY-MM-DD',
    'br-FR': 'DD/MM/YYYY',
    'smn-FI': 'D.M.YYYY',
    'moh-CA': 'M/D/YYYY',
    'arn-CL': 'DD-MM-YYYY',
    'ii-CN': 'YYYY/M/D',
    'dsb-DE': 'D. M. YYYY',
    'ig-NG': 'D/M/YYYY',
    'kl-GL': 'DD-MM-YYYY',
    'lb-LU': 'DD/MM/YYYY',
    'ba-RU': 'DD.MM.YY',
    'nso-ZA': 'YYYY/MM/DD',
    'quz-BO': 'DD/MM/YYYY',
    'yo-NG': 'D/M/YYYY',
    'ha-Latn-NG': 'D/M/YYYY',
    'fil-PH': 'M/D/YYYY',
    'ps-AF': 'DD/MM/YY',
    'fy-NL': 'D-M-YYYY',
    'ne-NP': 'M/D/YYYY',
    'se-NO': 'DD.MM.YYYY',
    'iu-Cans-CA': 'D/M/YYYY',
    'sr-Latn-RS': 'D.M.YYYY',
    'si-LK': 'YYYY-MM-DD',
    'sr-Cyrl-RS': 'D.M.YYYY',
    'lo-LA': 'DD/MM/YYYY',
    'km-KH': 'YYYY-MM-DD',
    'cy-GB': 'DD/MM/YYYY',
    'bo-CN': 'YYYY/M/D',
    'sms-FI': 'D.M.YYYY',
    'as-IN': 'DD-MM-YYYY',
    'ml-IN': 'DD-MM-YY',
    'en-IN': 'DD-MM-YYYY',
    'or-IN': 'DD-MM-YY',
    'bn-IN': 'DD-MM-YY',
    'tk-TM': 'DD.MM.YY',
    'bs-Latn-BA': 'D.M.YYYY',
    'mt-MT': 'DD/MM/YYYY',
    'sr-Cyrl-ME': 'D.M.YYYY',
    'se-FI': 'D.M.YYYY',
    'zu-ZA': 'YYYY/MM/DD',
    'xh-ZA': 'YYYY/MM/DD',
    'tn-ZA': 'YYYY/MM/DD',
    'hsb-DE': 'D. M. YYYY',
    'bs-Cyrl-BA': 'D.M.YYYY',
    'tg-Cyrl-TJ': 'DD.MM.YY',
    'sr-Latn-BA': 'D.M.YYYY',
    'smj-NO': 'DD.MM.YYYY',
    'rm-CH': 'DD/MM/YYYY',
    'smj-SE': 'YYYY-MM-DD',
    'quz-EC': 'DD/MM/YYYY',
    'quz-PE': 'DD/MM/YYYY',
    'hr-BA': 'D.M.YYYY.',
    'sr-Latn-ME': 'D.M.YYYY',
    'sma-SE': 'YYYY-MM-DD',
    'en-SG': 'D/M/YYYY',
    'ug-CN': 'YYYY-M-D',
    'sr-Cyrl-BA': 'D.M.YYYY',
    'es-US': 'M/D/YYYY',
  }
  const myDefault = 'MM/DD/YYYY'
  try {
    return formats[navigator.language] || myDefault
  } catch (err) { // if navigator isn't compatible with the user's browser
    return myDefault
  }
}

export function getDoesDayComeBeforeMonthInLocaleDateStringFormat() {
  const localeDateString = getLocaleDateString()
  return localeDateString.indexOf('D') < localeDateString.indexOf('M')
}

// CODE_COMMENTS_243: This doesn't actually return the user's local date string.
// The only two things it will return are 'MM/DD/YYYY' and 'DD/MM/YYYY'. Which
// one it returns depends on whether the user's actual localeDateString has the
// day or the month come first.
export function getDisplayedDateFormat(
  // react-datepicker, for example, wants dd and yyyy rather than DD and YYYY:
  // https://reactdatepicker.com/#example-custom-date-format
  lowercaseDayAndYear = false,
) {
  const doesDayComeBeforeMonthInLocaleDateStringFormat = getDoesDayComeBeforeMonthInLocaleDateStringFormat()
  return doesDayComeBeforeMonthInLocaleDateStringFormat
    ? lowercaseDayAndYear ? 'dd/MM/yyyy' : 'DD/MM/YYYY'
    : lowercaseDayAndYear ? 'MM/dd/yyyy' : 'MM/DD/YYYY'
}

// a helper function for below which returns the date as a moment() object. Pass
// in either a date string or a date object and this converts it to the proper
// format for the backend. If a date string is passed in which is not in the
// format of the DEFAULT_DISPLAYED_DATE_FORMAT, you must pass in the optional
// customDateFormat argument.
export function getDateAsMoment({
  date,
  customDateFormat,
  useUtcInsteadOfLocalTime,
}) {
  if (isString_(date)) {
    const momentObj = moment(date, customDateFormat || DEFAULT_DISPLAYED_DATE_FORMAT)
    if (useUtcInsteadOfLocalTime) {
      return momentObj.utc()
    }
    return momentObj
  }
  // d is a moment object
  if (useUtcInsteadOfLocalTime) {
    return date.utc()
  }
  return date
}

// All dates sent to the backend need to be in Unix Timestamp in milliseconds
// format. Pass in either a date string or moment() object and this converts
// it to the proper format for the backend. Also, this sets the dates to the
// Noon local time; see CODE_COMMENTS_154 for details. See the getDateAsMoment
// docstring for args details.
export function formatDateForApiCall({
  date,
  customDateFormat,
  startOfDayRatherThanNoonLocalTime = false,
  endOfDayRatherThanNoonLocalTime = false,
  // This is very unusual but there are times when it's necessary, for instance
  // with the NoMovements feature
  useUtcInsteadOfLocalTime,
}) {
  const dateMoment = getDateAsMoment({
    date,
    customDateFormat,
    useUtcInsteadOfLocalTime,
  })

  let dateToReturn
  if (startOfDayRatherThanNoonLocalTime) {
    dateToReturn = dateMoment.startOf('day')
  } else if (endOfDayRatherThanNoonLocalTime) {
    dateToReturn = dateMoment.endOf('day')
  } else {
    dateToReturn = dateMoment.clone().startOf('day').add(12, 'hours')
  }

  return dateToReturn.valueOf() // convert to Unix timestamp in milliseconds
}


// Often the web app queries the API for a set of results within two dates. We
// want these dates to be inclusive, i.e. the very first millisecond (midnight)
// of the start date and the very last millisecond (11:59:59.999pm) of the end
// date. This function returns an array of two dates suitable for such API
// calls. See the docstring of the formatDateForApiCall function for details on
// the args.
export function formatDateRangeForApiCall({
  startDate,
  endDate,
  customDateFormat,
  // This is very unusual but there are times when it's necessary, for instance
  // with the NoMovements feature
  useUtcInsteadOfLocalTime,
}) {
  return {
    startDate: formatDateForApiCall({
      date: startDate,
      customDateFormat,
      startOfDayRatherThanNoonLocalTime: true,
      useUtcInsteadOfLocalTime,
    }),
    endDate: formatDateForApiCall({
      date: endDate,
      customDateFormat,
      endOfDayRatherThanNoonLocalTime: true,
      useUtcInsteadOfLocalTime,
    }),
  }
}


/**
 * Returns an array of { month, day, year } objects of all instances of date
 * substrings in a string, e.g.
 *
 * "Expected maintenance 10/28/2019 17:00-10/29/2019 02:00"
 * ->
 * [
 *   { month: 10, day: 28, year: 2019 },
 *   { month: 10, day: 29, year: 2019 },
 * ]
 *
 * Definition of a date substring:
 *
 * - date strings must be formatted as MM/DD/YYYY or MM-DD-YYYY
 * - month and day values can be 1 digit instead of 2
 * - year value can be 2 digits instead of 4
 * - month value must be 1-12, day 1-31 (year can be anything)
 *
 * returns either an empty array or false if one or more conditions are not met.
 *
 * Note that this function does not check whether the date actually exists in
 * the real world. For instance, it will NOT return false for a date of
 * '02/31/2018' even though February only has 28 days (29 in a leap year). Use
 * the validateAndNormalizeDate() function for real-world date checking
 * functionality.
 *
 * Keep in mind that this function isn't bulletproof. For example:
 * 'Expected maintenance 10/28/2019 17:00-10/29/2019 02:00'
 * ->
 * [
 *   { month: 10, day: 28, year: 2019 },
 *   { month: 0, day: 10, year: 29 },
 * ]
 *
 * In a situation like above, the best alternative is to remove the time values
 * from the string before passing it into this function:
 * 'Expected maintenance 10/28/2019 -10/29/2019 '
 * ->
 * [
 *   { month: 10, day: 28, year: 2019 },
 *   { month: 10, day: 29, year: 2019 },
 * ]
 */
export function validateAndParseAllDatesInString({
  str,
  returnFalseRatherThanEmptyArrayIfNoMatches = false,
  // When this arg is true, the entire string must be a date string, with no
  // other characters before or after the date string. Also, rather than
  // returning an array of objects, a single object is returned. Also, if the
  // string is not a date string, false is always returned rather than an empty
  // array, no matter what returnFalseRatherThanEmptyArrayIfNoMatches is set to.
  singleDateStringFormat = false,
}) {
  // Comment A (referenced below): to write 1 or 2 digits here, we can't
  // simply write (\\d{1,2}). We're capturing these values to assign them to
  // their own variables later on (which is what the parentheses are for).
  // Therefore we can't simply write (\\d{1,2}), because that would only
  // capture the first digit of a two-digit input. We have to check for the
  // most digits first.
  /* eslint-disable no-multi-spaces */
  const dateRegex = new RegExp(
    [
      ...(singleDateStringFormat ? ['^'] : []), // start of string
      '(\\d{2}|\\d{1})',                  // 1 or 2 digits (but see Comment A above)
      '[\\/-]',                           // either a forward slash or a dash
      '(\\d{2}|\\d{1})',                  // 1 or 2 digits (but see Comment A above)
      '[\\/-]',                           // either a forward slash or a dash
      '(\\d{4}|\\d{2})',                  // 2 or 4 digits but not 3 (but see Comment A above)
      ...(singleDateStringFormat ? ['$'] : []), // end of string
    ].join(''),
    // This global flag is required because of the way we're calling .exec() on
    // the regex multiple times below; as
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
    // states, "be sure that the global flag ("g") is set or an infinite loop
    // will occur".
    'g',
  )
  /* eslint-enable no-multi-spaces */

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
  const toReturn = []
  let matchResults
  // eslint-disable-next-line no-cond-assign
  while ((matchResults = dateRegex.exec(str)) !== null) {
    const dateValues = matchResults.slice(1, 4) // get date portions
    // convert strings to integers
    let month
    let day
    let year
    if (getDoesDayComeBeforeMonthInLocaleDateStringFormat()) {
      [day, month, year] = dateValues.map(value => parseInt(value, 10))
    } else {
      [month, day, year] = dateValues.map(value => parseInt(value, 10))
    }
    toReturn.push({ month, day, year })
  }

  if (isTruthyAndNonEmpty(toReturn)) {
    return singleDateStringFormat
      ? toReturn[0]
      : toReturn
  }
  return returnFalseRatherThanEmptyArrayIfNoMatches || singleDateStringFormat
    ? false
    : toReturn // empty array
}

// Returns a date string formatted as MM/DD/YYYY (or MM-DD-YYYY if that's the
// separator used by the date string passed in) if the date string passed in is
// valid (see the end of this docstring for what's valid), returns false if not.
// If the year passed in is only two digits (10 instead of 2010), this function
// interprets it to mean somewhere between 50 years in the past and 50 years in
// the future.
//
// "Valid" dates mean parseable dates according to the validateAndParseDate()
// docstring and one additional feature that that function doesn't have: the
// date must actually exist in the real world. For instance, 2/31/2018 is not a
// real date because February only has 28 days (29 in a leap year), but the
// validateAndParseDate() function will return a value as if it were a real
// date. Only this function checks for whether the date actually exists or not.
export function validateAndNormalizeDate(date) {
  const individualDateVaues = validateAndParseAllDatesInString({
    str: date,
    singleDateStringFormat: true,
  })
  if (!individualDateVaues) { return individualDateVaues } // return false
  // eslint-disable-next-line prefer-const
  let { month, day, year } = individualDateVaues

  if (year < 100) {
    const closestCentury = whichCenturyIsClosest(year)
    let firstTwoDigitsOfCenturyForYear
    if (closestCentury === 'this') {
      firstTwoDigitsOfCenturyForYear = moment().format('YYYY').substring(0, 2)
    } else if (closestCentury === 'next') {
      firstTwoDigitsOfCenturyForYear = moment().add(100, 'years').format('YYYY').substring(0, 2)
    } else {
      firstTwoDigitsOfCenturyForYear = moment().subtract(100, 'years').format('YYYY').substring(0, 2)
    }
    year = `${firstTwoDigitsOfCenturyForYear}${year}`
  }

  // If the original date string contains a forward slash, use a forward slash
  // as the separator, otherwise use a dash.
  const separator = date.indexOf('/') > -1
    ? '/'
    : '-'
  let toReturn
  if (getDoesDayComeBeforeMonthInLocaleDateStringFormat()) {
    toReturn = `${padNumber(day, 2)}${separator}${padNumber(month, 2)}${separator}${year}`
  } else {
    toReturn = `${padNumber(month, 2)}${separator}${padNumber(day, 2)}${separator}${year}`
  }
  if (!moment(
    toReturn,
    separator === '/' ? DEFAULT_DISPLAYED_DATE_FORMAT : DEFAULT_DISPLAYED_DATE_FORMAT.replace(/\//g, '-'),
  ).isValid()) { return false }
  return toReturn
}


/**
 * Returns an array of { hour, minute, second, millisecond } objects of all
 * instances of time substrings in a string, e.g.
 *
 * 'Expected maintenance 10-28-2019 17:00-10-29-2019 02:00'
 * ->
 * [
 *   { hour: 17, minute: 0, second: 0, millisecond: 0 },
 *   { hour: 2, minute: 0, second: 0, millisecond: 0 },
 * ]
 *
 * Works with optional seconds and milliseconds, too:
 *
 * 'Expected maintenance 10-28-2019 17:15:45-10-29-2019 2:30:09.456789'
 * ->
 * [
 *   { hour: 17, minute: 15, second: 45, millisecond: 0 },
 *   { hour: 2, minute: 30, second: 9, millisecond: 456789 },
 * ]
 *
 *
 * Works with a.m. and p.m. too:
 *
 * 'Expected maintenance 10-28-2019 5:15:45 pm-10-29-2019 2:30:09.456789 am'
 * ->
 * [
 *   { hour: 17, minute: 15, second: 45, millisecond: 0 },
 *   { hour: 2, minute: 30, second: 9, millisecond: 456789 },
 * ]
 *
 * Definition of a time substring:
 *
 * - time strings must be formatted as HH:MM or HH:MM:SS or HH:MM:SS.S+
 * - values can be 1 digit instead of 2
 * - hour value must be 0-23, minute and second 0-59
 *
 * returns either an empty array or false if one or more conditions are not met.
 */
export function validateAndParseAllTimesInString({
  str,
  returnFalseRatherThanEmptyArrayIfNoMatches = false,
  // When this arg is true, the entire string must be a single time string, with
  // no other characters before or after the time string. Also, rather than
  // returning an array of objects, a single object is returned. Also, if the
  // string is not a time string, false is always returned rather than an empty
  // array, no matter what returnFalseRatherThanEmptyArrayIfNoMatches is set to.
  singleTimeStringFormat = false,
}) {
  let matches = findMatchesOfAllTimesInString({
    str,
    returnFalseRatherThanEmptyArrayIfNoMatches,
    singleTimeStringFormat,
  })
  if (!isTruthyAndNonEmpty(matches)) {
    return matches
  }

  if (singleTimeStringFormat) {
    matches = [matches]
  }

  const toReturn = matches.map(match => {
    const values = match.slice(1, 6)
    const allValuesExceptAmPm = values.slice(0, 4)
    const amPm = values[4]
    // convert strings to integers
    // eslint-disable-next-line prefer-const
    let [hour, minute, second, millisecond] = allValuesExceptAmPm.map(value => parseInt(value || 0, 10))
    if (amPm && (amPm.includes('p') || amPm.includes('P'))) {
      hour += 12
    }
    return { hour, minute, second, millisecond }
  })

  return singleTimeStringFormat
    ? toReturn[0]
    : toReturn
}


// 'Expected maintenance 10-28-2019 17:15:45-10-29-2019 2:30:09.456789'
// ->
// 'Expected maintenance 10-28-2019 -10-29-2019 '
export function removeAllTimesFromString({ str }) {
  const matches = findMatchesOfAllTimesInString({
    str,
    returnFalseRatherThanEmptyArrayIfNoMatches: false,
    singleTimeStringFormat: false,
  })
  return matches.reduce(
    (acc, match) => acc.replace(match[0], ''),
    str,
  )
}

/**
 * Returns an array of arrays, one for each instance of a time substring
 * in a string. Each array is a result array from calling the Regexp.exec()
 * function:
 *
 * 'Expected maintenance 10-28-2019 05:15:45.456789 pm-10-29-2019 2:30 am'
 * ->
 * [
 *   [
 *     '5:15:45 pm',   // full match
 *     '05',           // Hour
 *     '15',           // Minute
 *     '45',           // Second
 *     '456789',       // millisecond
 *     'pm',           // am/pm
 *     index: 32,
 *     input: 'Expected maintenance 10-28-2019 5:15:45 pm-10-29-2019 2:30:09.456789 am',
 *     groups: undefined
 *   ],
 *   [
 *     '2:30 am',      // full match
 *     '2',            // Hour
 *     '30',           // Minute
 *     undefined,      // Second
 *     undefined,      // millisecond
 *     'am',           // am/pm
 *     index: 32,
 *     input: 'Expected maintenance 10-28-2019 5:15:45 pm-10-29-2019 2:30:09.456789 am',
 *     groups: undefined
 *   ],
 * ]
 *
 * Definition of a time substring:
 *
 * - time strings must be formatted as either HH:MM, HH:MM:SS or HH:MM:SS.S+,
 *   am/pm optional.
 * - values can be 1 digit instead of 2
 * - hour value must be 0-23, minute and second 0-59
 *
 * returns either an empty array or false if one or more conditions are not met.
 */
function findMatchesOfAllTimesInString({
  str,
  returnFalseRatherThanEmptyArrayIfNoMatches = false,
  // When this arg is true, the entire string must be a single time string, with
  // no other characters before or after the time string. Also, rather than
  // returning an array of arrays, a single array is returned. Also, if the
  // string is not a time string, false is always returned rather than an empty
  // array, no matter what returnFalseRatherThanEmptyArrayIfNoMatches is set to.
  singleTimeStringFormat = false,
}) {
  // Adapted from https://stackoverflow.com/a/8318367/6995996
  /* eslint-disable no-multi-spaces */
  const regexStringParts = [
    '([01]?\\d|2[0-3])',           // HH
    ':([0-5]?\\d)',                // :MM
    '(?:',                         // Optionally try to match (seconds)...
    ':([0-5]?\\d)',                // :SS
    '(?:',                         // Optionally try to match (milliseconds)...
    '\\.(\\d+)',                   // .S+
    ')?',                          // End of "Optionally try to match (milliseconds)..."
    ')?',                          // End of "Optionally try to match (seconds)..."
    '(?:',                         // Optionally try to match (am/pm)...
    '\\s*',                        // one or more spaces
    '([aApP]\\s*\\.?\\s*[mM])',    // am/pm
    ')?',                          // End of "Optionally try to match (am/pm)..."
  ]
  /* eslint-enable no-multi-spaces */

  if (singleTimeStringFormat) {
    regexStringParts.unshift('^')
    regexStringParts.push('$')
  }

  const myRegex = new RegExp(
    regexStringParts.join(''),
    // This global flag is required because of the way we're calling .exec() on
    // the regex multiple times below; as
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
    // states, "be sure that the global flag ("g") is set or an infinite loop
    // will occur".
    'g',
  )
  /* eslint-enable no-multi-spaces */

  const toReturn = []
  let matchResults
  // eslint-disable-next-line no-cond-assign
  while ((matchResults = myRegex.exec(str)) !== null) {
    toReturn.push(matchResults)
  }

  if (isTruthyAndNonEmpty(toReturn)) {
    return singleTimeStringFormat
      ? toReturn[0]
      : toReturn
  }
  return returnFalseRatherThanEmptyArrayIfNoMatches || singleTimeStringFormat
    ? false
    : toReturn // empty array
}

// 'Expected maintenance 10/28/2019 17:00-20:00'
// ->
// {
//   start: 1572368400000,
//   end: 1572379200000,
// }
// Also works with e.g. 'Expected maintenance 10/28/2019 17:00-10/29/2019 03:00'
export function extractDateRangeFromString({
  str,
  // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones e.g.
  // 'America/Denver'
  timezone = 'Etc/GMT', // same as UTC
  returnedFormat = 'timestamps', // 'timestamps' or 'moment objects'
}) {
  const times = validateAndParseAllTimesInString({
    str,
    returnFalseRatherThanEmptyArrayIfNoMatches: true,
  }) || [
    { hour: 0, minute: 0, second: 0, millisecond: 0 },
    { hour: 0, minute: 0, second: 0, millisecond: 0 },
  ]
  const strNoTimes = removeAllTimesFromString({ str })
  let dates = validateAndParseAllDatesInString({
    str: strNoTimes,
    returnFalseRatherThanEmptyArrayIfNoMatches: true,
  })
  if (!dates || dates.length === 0) {
    return {
      start: null,
      end: null,
    }
  }
  if (dates.length === 1) {
    dates = [dates[0], dates[0]]
  }

  const startObj = {
    ...dates[0],
    ...times[0],
    month: dates[0].month - 1, // CODE_COMMENTS_239
  }
  const start = moment(startObj).tz(timezone)
  const endObj = {
    ...dates[1],
    ...times[1],
    month: dates[1].month - 1, // CODE_COMMENTS_239
  }
  const end = moment(endObj).tz(timezone)

  if (returnedFormat === 'moment objects') {
    return {
      start,
      end,
    }
  }

  return {
    start: start.valueOf(), // convert to Unix timestamp in milliseconds
    end: end.valueOf(), // convert to Unix timestamp in milliseconds
  }
}


/**
 * Pass in the last two digits of a year (as an int) and this function will tell
 * you which century is closest to the current year. returns one of 3 strings:
 * "this", "next" or "last"
 */
function whichCenturyIsClosest(year) {
  const thisYear = parseInt(moment().format('YYYY').substring(2, 4), 10)
  if (Math.abs(thisYear - year) <= 50) { return 'this' }
  if (thisYear - year < 0) { return 'last' }
  return 'next'
}

/**
 * stackoverflow.com/a/10073788
 */
function padNumber(n, width, z = '0') {
  // eslint-disable-next-line no-param-reassign
  n += ''
  // eslint-disable-next-line no-mixed-operators
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}


export function formatApiDate(apiDate, displayFormat) {
  return convertApiDateToMoment(apiDate).format(displayFormat)
}

// pass in a date string and its format and this returns a sting like 20180428
export function formatDateStringForFilenameCompatibility(
  dateString,
  dateStringFormat = DEFAULT_DISPLAYED_DATE_FORMAT,
) {
  return moment(dateString, dateStringFormat).format('YYYYMMDD')
}

// Returns 'April, 2018'
export function convertMomentToMonthAndYearString({
  momentObj,
}) {
  return momentObj.format('MMMM, YYYY')
}

// Returns 'April, 2018'
export function convertApiDateToMonthAndYearString(apiDate) {
  return convertMomentToMonthAndYearString({ momentObj: convertApiDateToMoment(apiDate) })
}


/**
 * Pass in two moment objects and it returns an array of successive year strings
 * that fall within the range.
 */
export function createSuccessiveYearStringsFromRange(min, max) {
  if (min > max) { return [] }
  let minNew = min

  const toReturn = []
  while (minNew < max) {
    toReturn.push(min.format('YYYY'))
    minNew = minNew.add(1, 'years')
  }
  if (toReturn[toReturn.length - 1] !== max.format('YYYY')) {
    toReturn.push(max.format('YYYY'))
  }
  return toReturn
}


/**
 * See docsting above
 */
export function createSuccessiveMonthNameStringsFromRange(min, max) {
  if (min > max) { return [] }
  const toReturn = []
  while (
    min.startOf('month') <= max.startOf('month')
    &&
    toReturn.length < 12
  ) {
    toReturn.push(min.format('MMMM'))
    // eslint-disable-next-line no-param-reassign
    min = min.clone().add(1, 'month')
  }
  return toReturn
}


/**
 * See docsting above
 */
export function createSuccessiveMonthAndYearStringsFromRange(min, max) {
  if (min > max) { return [] }
  const toReturn = []
  while (min.startOf('month') <= max.startOf('month')) {
    toReturn.push(convertMomentToMonthAndYearString({ momentObj: min }))
    // eslint-disable-next-line no-param-reassign
    min = min.clone().add(1, 'month')
  }
  return toReturn
}

export function convertMonthAndYearStringToMoment(str) {
  return moment(str, 'MMMM, YYYY')
}


export function doesHistoryItemDateFallWithinHistoryFormDateRange(
  itemDateFromApi, // a date straight from the API
  chosenStartDateString, // string in DEFAULT_DISPLAYED_DATE_FORMAT format
  chosenEndDateString, // string in DEFAULT_DISPLAYED_DATE_FORMAT format
) {
  const itemDate = convertApiDateToMoment(itemDateFromApi)
  const chosenStartDate = moment(chosenStartDateString, DEFAULT_DISPLAYED_DATE_FORMAT)
  const chosenEndDate = moment(chosenEndDateString, DEFAULT_DISPLAYED_DATE_FORMAT)

  return (
    // CODE_COMMENTS_80
    itemDate.isSameOrAfter(chosenStartDate, 'day') &&
    itemDate.isSameOrBefore(chosenEndDate, 'day')
  )
}

/**
 * 17 days ago
 * 2 days ago
 * Yesterday
 * Today
 * Tomorrow
 * 2 days from now
 * 54729 days from now
 */
export function convertNumberToDaysFromNowNaturalLanguage(num) {
  if (num < -1) {
    return `${Math.abs(num)} days ago`
  } else if (num === -1) {
    return 'Yesterday'
  } else if (num === 0) {
    return 'Today'
  } else if (num === 1) {
    return 'Tomorrow'
  }
  return `${num} days from now`
}


/*
 * *****************************************************************************
 * Working with Functions
 * *****************************************************************************
*/

// Just like combineFilterFunctions except some of your args can be undefined
// and they'll be filtered out
export function combineFilterFunctionsIgnoringUndefinedArgs(...filterFunctions) {
  const filterFunctionsNoUndefined = filterFunctions.filter(f => f)
  return overEvery_(filterFunctionsNoUndefined)
}


// https://stackoverflow.com/a/47803303
export function combineFilterFunctions(...filterFunctions) {
  return overEvery_(filterFunctions)
}


/*
 * *****************************************************************************
 * Item SKUs and Container Types
 * *****************************************************************************
*/

export function getAllContainerTypes() {
  return CONTAINER_TYPES.map(type => type.id)
}

export function getContainerTypesDefaultSortOrder() {
  return CONTAINER_TYPES.map(type => type.id)
}

/**
 * Returns the string passed in if no human readable name is found.
 */
export function getHumanReadableContainerType(containerTypeId) {
  const target = CONTAINER_TYPES.find(containerType => containerType.id === containerTypeId)
  return target
    ? target.name
    : containerTypeId
}

/**
 * Returns the string passed in if no human readable name is found.
 */
export function getHumanReadableContainerTypeInFractionBblForm(containerTypeId) {
  const target = CONTAINER_TYPES.find(containerType => containerType.id === containerTypeId)
  if (target) {
    const fractionBblName = target.fractionBblName
    return fractionBblName || containerTypeId
  }
  return containerTypeId
}


/*
 * *****************************************************************************
 * Names and Constants
 * *****************************************************************************
*/

export function getHumanReadableCustomerType(customerType) {
  return CUSTOMER_TYPES_TO_HUMAN_READABLE_NAME_MAP[customerType]
}

/**
 * The API's movementType enums are formatted as two customer types joined with
 * a "2", for example: BRW2BRW and CONBRW2DIST
 */
export function createShipmentType(customerTypeOrigin, customerTypeDestination) {
  return `${customerTypeOrigin}2${customerTypeDestination}`
}

/**
 * Returns a list of the two customer types in order from left to right:
 *
 * 'BRW2DIST' -> ['BRW', 'DIST']
 */
export function parseShipmentType(movementType) {
  return movementType.split('2')
}

/**
 * converts a shipment type into human readable:
 *
 * 'BRW2DIST' -> 'Brewer to Distributor'
 */
export function getHumanReadableShipmentType(movementType) {
  return movementType.split('2').map(
    custType => getHumanReadableCustomerType(custType),
  ).join(' to ')
}


// needed by both the createFormNameForRedux() and parseFormNameForRedux() functions
const REDUX_LABEL_CONBRW_CUSTOMER_ID = 'ConbrwCid'
const REDUX_LABEL_ADDITIONAL_IDENTIFIER = 'additionalIdentifier'

// CODE_COMMENTS_196
export function createCustIdAndOptionalConbrwCustIdReduxIdentifier(
  customerId,
  contractBrewerCustomerId,
) {
  if (contractBrewerCustomerId) {
    return `${customerId}${CONCATENATED_STRING_SEPARATOR}${REDUX_LABEL_CONBRW_CUSTOMER_ID}${CONCATENATED_STRING_SEPARATOR}${contractBrewerCustomerId}`
  }
  return customerId
}


// CODE_COMMENTS_196
export function parseCustIdAndOptionalConbrwCustIdReduxIdentifier(identifier) {
  const splitUp = identifier.split(CONCATENATED_STRING_SEPARATOR)
  const customerId = splitUp[0]

  // These other things are optional
  const contractBrewerCustomerId = (
    splitUp.includes(REDUX_LABEL_CONBRW_CUSTOMER_ID)
    && splitUp[splitUp.indexOf(REDUX_LABEL_CONBRW_CUSTOMER_ID) + 1]
  )

  return { customerId, contractBrewerCustomerId }
}


/**
  * Returns a string to be used as an identifier for a specific form if that
  * form has any info that needs to be held in Redux, such as fetch statuses.
  * additionalIdentifier is something like an order ID for the "Edit Keg Order"
  * form or a shipment ID for an "Edit Shipment" form.
  */
export const createFormNameForRedux = ({
  reducerName,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_196
  additionalIdentifier,
}) => {
  const reduxId = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
    customerId,
    operatingContractBrewerCustomerId,
  )
  let toReturn = `${reducerName}${CONCATENATED_STRING_SEPARATOR}${reduxId}`
  if (additionalIdentifier) {
    toReturn += `${CONCATENATED_STRING_SEPARATOR}${REDUX_LABEL_ADDITIONAL_IDENTIFIER}${CONCATENATED_STRING_SEPARATOR}${additionalIdentifier}`
  }
  return toReturn
}


/**
  * parses a form field name of a quantity field in the
  * order kegs form. Returns an object {reducerName, customerId}
  */
export const parseFormNameForRedux = formName => {
  const splitUp = formName.split(CONCATENATED_STRING_SEPARATOR)
  const reducerName = splitUp[0]
  const customerId = splitUp[1]

  // These other things are optional
  const operatingContractBrewerCustomerId = REDUX_LABEL_CONBRW_CUSTOMER_ID in splitUp &&
    splitUp[splitUp.indexOf(REDUX_LABEL_CONBRW_CUSTOMER_ID) + 1]
  const additionalIdentifier = REDUX_LABEL_ADDITIONAL_IDENTIFIER in splitUp &&
    splitUp[splitUp.indexOf(REDUX_LABEL_ADDITIONAL_IDENTIFIER) + 1]

  return { reducerName, customerId, operatingContractBrewerCustomerId, additionalIdentifier }
}


export const getCustomerIdFromFormName = formName => (
  parseFormNameForRedux(formName).customerId
)


// CODE_COMMENTS_84
export const createReduxNormalizedLinkId = (linkedId1, linkedId2) => (
  `${linkedId1}${CONCATENATED_STRING_SEPARATOR}${linkedId2}`
)


export function getBusinessUnitNameFromBusinessUnitId(businessUnitId) {
  return BUSINESS_UNIT_ID_TO_NAME_MAP[businessUnitId]
}

// CODE_COMMENTS_241
export function getIsUniversalCustomerRepType(repType) {
  return ALL_UNIVERSAL_CUSTOMER_REPS.includes(repType)
}


/*
 * *****************************************************************************
 * Form Input Validation
 * *****************************************************************************
*/

/**
 * https://stackoverflow.com/a/46181
 */
export function getIsStringAValidEmailAddress(str) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(str.toLowerCase())
}

/**
 * Returns null if the password is valid.
 */
export function getInvalidPasswordErrorText(pw) {
  if (pw.length < LOGIN_PASSWORD_MIN_LENGTH) {
    return 'Password must be at least 8 characters long'
  } else if (pw.length > LOGIN_PASSWORD_MAX_LENGTH) {
    return PASSWORD_INPUT_TOO_LONG_ERROR_MSG
  } else if (!doesStringContainBothUppercaseAndLowercaseLetters(pw)) {
    return 'Password must contain both uppercase and lowercase letters'
  } else if (!doesStringContainBothLettersAndNumbers(pw)) {
    return 'Password must contain both letters and numbers'
  }
  return null
}

export function doesStringContainBothLettersAndNumbers(str) {
  const re = /^(?=.*[a-zA-Z])(?=.*[0-9])/ // both letters and numbers
  return re.test(str)
}

export function doesStringContainBothUppercaseAndLowercaseLetters(str) {
  return str.toUpperCase() !== str && str.toLowerCase() !== str
}

export function doesStringContainNumbersAndOrForwardSlashesAndOrHyphensOnly(str) {
  if (str.length === 0) { return false }
  return /^[\d/-]*$/.test(str)
}

/*
 * Zero-padding in front is rejected: "15" returns true, "015" returns false.
 * Also, "+15" and "-15" return false.
*/
export function isStringNonZeroPaddedPositiveInteger(str) {
  if (isStringStartZeroPadded(str)) { return false }
  return getDoesStringContainDigitsOnly(str)
}

// by non-zero-padded negative integer, we mean '-1' returns true, '-01' returns
// false
export function isStringNonZeroPaddedNegativeInteger(str) {
  if (str[0] !== '-') { return false }
  const afterNegativeSymbol = str.substring(1)
  return isStringNonZeroPaddedPositiveInteger(afterNegativeSymbol)
}


export function isStringNonZeroPaddedPositiveOrNegativeInteger(str) {
  if (str[0] !== '-') {
    return isStringNonZeroPaddedPositiveInteger(str)
  }
  return isStringNonZeroPaddedNegativeInteger(str)
}

export function isStringStartZeroPadded(str) {
  return str.charAt(0) === '0'
}

export function getDoesStringContainDigitsOnly(str) {
  return /^\d+$/.test(str)
}

// This function is a bit tricky because the goal is that it work for every
// single form in the app. It also needs to account for a hypothetical situation
// where when you set an <input> value to an empty string, the value might get
// set to an empty string, or to null/undefined, or the field property might be
// deleted from formValues altogether.
//
// NOTE: DO NOT use on forms which contain field arrays. Both the formValues and
// initialValues parameters must be objects whose properties are actual form
// values, not objects or arrays. If you want to use this function to test forms
// with field arrays, use the getAreFormValuesSameAsInitialValues function.
function getAreFormValuesSameAsInitialValuesNoFieldArrays(formValues, initialValues) {
  /* eslint-disable no-param-reassign */
  if (!formValues) { formValues = {} }
  if (!initialValues) { initialValues = {} }
  /* eslint-enable no-param-reassign */

  const formValuesKeys = Object.keys(formValues)
  const initialValuesKeys = Object.keys(initialValues)

  // Make sure that every value in initialValues is also in formValues
  if (!initialValuesKeys.every(initialValueKey => (
    formValuesKeys.includes(initialValueKey)
    ||
    // It's possible that an initial value has been set to an empty string,
    // null, undefined or false.
    !initialValues[initialValueKey]
    ||
    // Throughout all our forms, a value of 0 is the same as undefined
    // (this is particularly important in Keg Quantity fields)
    initialValues[initialValueKey] === '0'
  ))) {
    return false
  }

  return Object.keys(formValues).every(formValueKey => {
    const formValue = formValues[formValueKey]
    // If a field that appears in the form values is not defined in the initial
    // values, that doesn't necessarily mean form values and initial values are
    // unequal in the way this function defines equality. If the form value is
    // falsy, then they're the same by this function's definition. A practical
    // example is checkboxes: let's say we want the initial value to be
    // unchecked so we don't set any initial value. The user checks then
    // unchecks the checkbox. That field's value might be set to false.
    //
    // Also, throughout all our forms, a value of 0 is the same as undefined
    // (this is particularly important in Keg Quantity fields)
    if (!initialValuesKeys.includes(formValueKey)) {
      return (!formValue || formValue === '0')
    }

    // if the form value is in the initial values
    const initialValue = initialValues[formValueKey]

    // if both the form value and the initial value are falsy, they're the same.
    // This actually happens in the app: when we set the initial values of the
    // "Edit Keg Order" form, we set the "Internal Reference #" and "Order
    // Comments" fields to exactly what they are as they come from the API; if
    // the user left these fields blank when originally placing the order, the
    // API will set these properties to undefined. Then, if the user types in a
    // value for either of these fields in the "Edit Keg Order" form but then
    // deletes the value, leaving the field empty again, the form value will be
    // set to undefined.
    //
    // Also, throughout all our forms, a value of 0 is the same as undefined
    // (this is particularly important in Keg Quantity fields)
    if ((!formValue || formValue === '0') && (!initialValue || initialValue === '0')) { return true }

    // This handles cases where the formValue is a moment object and the
    // initialValue is a string representing the same moment. It also handles
    // the opposite case.
    if (moment.isMoment(formValue) || moment.isMoment(initialValue)) {
      if (moment.isMoment(formValue) && moment.isMoment(initialValue)) {
        return formValue.isSame(initialValue)
      }
      if (moment.isMoment(formValue)) {
        // We don't need to wrap this in a try block because it's impossible for
        // this to throw an error (doesn't matter what the value is, string
        // number, object, Nan, Infinity)
        const initialValueAsMoment = moment(initialValue, DEFAULT_DISPLAYED_DATE_FORMAT)
        return formValue.isSame(initialValueAsMoment)
      }
      // initialValue is a moment object and formValue is not
      const formValueAsMoment = moment(formValue, DEFAULT_DISPLAYED_DATE_FORMAT)
      return initialValue.isSame(formValueAsMoment)
    }

    if (isNumber_(formValue) || isNumber_(initialValue)) {
      return Number(formValue) === Number(initialValue)
    }

    // Now that we've taken care of the special cases of both moment objects and
    // numbers, formValue and initialValue can only be a string or boolean,
    // which means we're safe to do a simple equality check.
    return formValue === initialValue
  })
}


function getAreFormValuesInFieldArrayTheSameAsInitialValuesInCorollaryFieldArray(
  formValuesFieldArray,
  initialValuesFieldArray,
) {
  // If formValuesFieldArrayis an empty array or an array of empty objects and
  // initialValuesFieldArray is undefined (or the other way around), they're the
  // same (in the way this function defines sameness).
  const isFormValuesFieldArrayEmptyOrUndefined = !isTruthyAndNonEmpty(formValuesFieldArray)
  const isInitialValuesFieldArrayEmptyOrUndefined = !isTruthyAndNonEmpty(initialValuesFieldArray)

  if (
    isFormValuesFieldArrayEmptyOrUndefined &&
    isInitialValuesFieldArrayEmptyOrUndefined
  ) { return true }

  if (isInitialValuesFieldArrayEmptyOrUndefined) {
    if (formValuesFieldArray.some(row => isTruthyAndNonEmpty(row))) { return false }
  }

  if (isFormValuesFieldArrayEmptyOrUndefined) {
    if (initialValuesFieldArray.some(row => isTruthyAndNonEmpty(row))) { return false }
  }


  // now iterate through whichever fieldArray has more rows in it, comparing
  // each row to row in the correlary field array
  const lengthOfLongestFieldArray = Math.max(formValuesFieldArray.length, initialValuesFieldArray.length)
  return range_(lengthOfLongestFieldArray).every(index => (
    getAreFormValuesSameAsInitialValuesNoFieldArrays(
      formValuesFieldArray[index],
      initialValuesFieldArray[index],
    )
  ))
}


// Determines whether current form values are the same as the initial form
// values. See the getAreFormValuesSameAsInitialValuesNoFieldArrays docstring
// for details. The 'fieldArrayNames' argument should be an array of strings
// identifying which props in the 'formValues' and 'initialValues' props are
// field arrays. If the form does not have field arrays, simply omit the
// 'fieldArrayNames' argument. NOTE: this function will only work for forms with
// field arrays if field arrays are top-level props within the objects passed
// in. If a form ever gets complex enough that it nests field arrays within
// other fields, this function will need to be refactored.
export function getAreFormValuesSameAsInitialValues(formValues, initialValues, fieldArrayNames) {
  if (!fieldArrayNames) {
    return getAreFormValuesSameAsInitialValuesNoFieldArrays(formValues, initialValues)
  }

  // If the field array of formValues is an empty array or an array of empty
  // objects and initialValues is undefined (or the other way around), they're
  // the same in the way this function defines sameness
  if (!fieldArrayNames.every(fieldArrayName => (
    getAreFormValuesInFieldArrayTheSameAsInitialValuesInCorollaryFieldArray(
      formValues[fieldArrayName],
      initialValues[fieldArrayName],
    )))
  ) {
    return false
  }

  return getAreFormValuesSameAsInitialValuesNoFieldArrays(
    omit_(formValues, fieldArrayNames),
    omit_(initialValues, fieldArrayNames),
  )
}


/*
 * *****************************************************************************
 * Redux-like stuff
 * *****************************************************************************
*/

// Swiped from
// https://github.com/redux-utilities/reduce-reducers/blob/master/src/index.js
// (why not just npm install reduce-reducers? Because this is so simple can be
// copy-pasted straight from the package, that way we don't have to deal with
// package upgrades.) Helpful when multiple components need to edit the same
// local form state (for example, the history forms). See
// https://stackoverflow.com/a/44371190 for how this is different from Redux's
// combineReducers().
export const reduceReducers = (...args) => {
  const initialState = typeof args[0] !== 'function' && args.shift()
  const reducers = args

  if (typeof initialState === 'undefined') {
    throw new TypeError(
      'The initial state may not be undefined. If you do not want to set a value for this reducer, you can use null instead of undefined.',
    )
  }

  return (prevState, value, ...args_) => {
    const prevStateIsUndefined = typeof prevState === 'undefined'
    const valueIsUndefined = typeof value === 'undefined'

    if (prevStateIsUndefined && valueIsUndefined && initialState) {
      return initialState
    }

    return reducers.reduce((newState, reducer, index) => {
      if (typeof reducer === 'undefined') {
        throw new TypeError(
          `An undefined reducer was passed in at index ${index}`,
        )
      }

      return reducer(newState, value, ...args_)
    }, prevStateIsUndefined && !valueIsUndefined && initialState ? initialState : prevState)
  }
}


/*
 * *****************************************************************************
 * Content for Display
 * *****************************************************************************
*/

// CODE_COMMENTS_277. This component has sane default widths for each
// breakpoint, but you can customize them by passing in as few or as many
// entries as you'd like, e.g.
// {
//   widescreen: 4,
//   largeScreen: 4,
// }
export function getLabelAndFieldWidthsForHorizontalFormSection(customLabelWidths) {
  const labelWidths = {
    ...DEFAULT_LABEL_WIDTHS_FOR_HORIZONTAL_FORM_SECTION,
    ...(isPlainObject_(customLabelWidths) ? customLabelWidths : {}),
  }

  const fieldWidths = Object.entries(labelWidths).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: 16-value,
    }),
    {},
  )
  return [labelWidths, fieldWidths]
}

/**
 * Returns an array of strings which, if printed one after another on new lines,
 * displays a properly-formatted address.
 */
export function processAddressObjectForDisplay(
  address,
  // Include the country by default when displaying addresses (yes, even USA);
  // see https://microstartap3.atlassian.net/browse/TP3-442
  includeCountry = true,
) {
  const a = []
  if (address.name) { a.push(address.name) }
  if (address.address1) { a.push(address.address1) }
  if (address.address2) { a.push(address.address2) }
  if (address.address3) { a.push(address.address3) }
  a.push(createCityStateCountryAndPostalCodeStringForDisplayedAddress(address, includeCountry))
  return a
}

export function processAddressObjectForScheduleShipmentAddress(
    address,
    includeCountry = true,
) {
  const a = []
  if (address.name) { a.push(address.name) }
  if (address.address1) { a.push(address.address1) }
  if (address.address2) { a.push(address.address2) }
  if (address.address3) { a.push(address.address3) }
  a.push(createCityStateCountryAndPostalCodeStringForDisplayedAddress(address, includeCountry))
  return a.join(', ')
}

// Same as processAddressObjectForDisplay except that address1, address2, and
// address3 are all one string (as one array item).
export function processAddressObjectForDisplayWithStreetAddressOnOneLine(
  address,
  // Include the country by default when displaying addresses (yes, even USA);
  // see https://microstartap3.atlassian.net/browse/TP3-442
  includeCountry = true,
) {
  // process street address first
  const streetAddress = []
  if (address.address1) { streetAddress.push(address.address1) }
  if (address.address2) { streetAddress.push(address.address2) }
  if (address.address3) { streetAddress.push(address.address3) }
  const streetAddressString = streetAddress.join(' ')

  const a = []
  a.push(streetAddressString)
  a.push(createCityStateCountryAndPostalCodeStringForDisplayedAddress(address, includeCountry))
  return a
}


export function getUsStateNameFromUsStateAbbreviation(abbreviation) {
  const state = US_STATES.find(stateObj => stateObj.abbreviation === abbreviation)
  if (!state) { return undefined }
  return state.name
}


function createCityStateCountryAndPostalCodeStringForDisplayedAddress(address, includeCountry) {
  const cityAndState = `${address?.city || ''}, ${address?.stateOrProvince || ''}`
  const country = includeCountry && address.country && convertCountryAlpha2CodeToAlpha3(address.country)
  const postalCode = address?.postalCode || ''
  return `${cityAndState} ${country ? `${country} ` : ''}${postalCode}`
}

// This list is from
// https://github.com/vtex/country-iso-2-to-3/blob/master/index.js. The only
// time we ever need to deal with country codes in the web app is when
// converting alpha-2 codes (which are 2 digits, e.g. 'US', and is how the
// database stores address countries) to alpha-3 codes (3 digits, e.g. 'USA')
// (the only reason we do this is because the web app developers think alpha-3
// codes are more human-readable), so there's no reason to add such a
// heavyweight solution like https://www.npmjs.com/package/i18n-iso-countries.
const countryAlpha2ToAlpha3Mapping = {
  AF: 'AFG',
  AX: 'ALA',
  AL: 'ALB',
  DZ: 'DZA',
  AS: 'ASM',
  AD: 'AND',
  AO: 'AGO',
  AI: 'AIA',
  AQ: 'ATA',
  AG: 'ATG',
  AR: 'ARG',
  AM: 'ARM',
  AW: 'ABW',
  AU: 'AUS',
  AT: 'AUT',
  AZ: 'AZE',
  BS: 'BHS',
  BH: 'BHR',
  BD: 'BGD',
  BB: 'BRB',
  BY: 'BLR',
  BE: 'BEL',
  BZ: 'BLZ',
  BJ: 'BEN',
  BM: 'BMU',
  BT: 'BTN',
  BO: 'BOL',
  BA: 'BIH',
  BW: 'BWA',
  BV: 'BVT',
  BR: 'BRA',
  VG: 'VGB',
  IO: 'IOT',
  BN: 'BRN',
  BG: 'BGR',
  BF: 'BFA',
  BI: 'BDI',
  KH: 'KHM',
  CM: 'CMR',
  CA: 'CAN',
  CV: 'CPV',
  KY: 'CYM',
  CF: 'CAF',
  TD: 'TCD',
  CL: 'CHL',
  CN: 'CHN',
  HK: 'HKG',
  MO: 'MAC',
  CX: 'CXR',
  CC: 'CCK',
  CO: 'COL',
  KM: 'COM',
  CG: 'COG',
  CD: 'COD',
  CK: 'COK',
  CR: 'CRI',
  CI: 'CIV',
  HR: 'HRV',
  CU: 'CUB',
  CY: 'CYP',
  CZ: 'CZE',
  DK: 'DNK',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  EC: 'ECU',
  EG: 'EGY',
  SV: 'SLV',
  GQ: 'GNQ',
  ER: 'ERI',
  EE: 'EST',
  ET: 'ETH',
  FK: 'FLK',
  FO: 'FRO',
  FJ: 'FJI',
  FI: 'FIN',
  FR: 'FRA',
  GF: 'GUF',
  PF: 'PYF',
  TF: 'ATF',
  GA: 'GAB',
  GM: 'GMB',
  GE: 'GEO',
  DE: 'DEU',
  GH: 'GHA',
  GI: 'GIB',
  GR: 'GRC',
  GL: 'GRL',
  GD: 'GRD',
  GP: 'GLP',
  GU: 'GUM',
  GT: 'GTM',
  GG: 'GGY',
  GN: 'GIN',
  GW: 'GNB',
  GY: 'GUY',
  HT: 'HTI',
  HM: 'HMD',
  VA: 'VAT',
  HN: 'HND',
  HU: 'HUN',
  IS: 'ISL',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  IE: 'IRL',
  IM: 'IMN',
  IL: 'ISR',
  IT: 'ITA',
  JM: 'JAM',
  JP: 'JPN',
  JE: 'JEY',
  JO: 'JOR',
  KZ: 'KAZ',
  KE: 'KEN',
  KI: 'KIR',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KWT',
  KG: 'KGZ',
  LA: 'LAO',
  LV: 'LVA',
  LB: 'LBN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MK: 'MKD',
  MG: 'MDG',
  MW: 'MWI',
  MY: 'MYS',
  MV: 'MDV',
  ML: 'MLI',
  MT: 'MLT',
  MH: 'MHL',
  MQ: 'MTQ',
  MR: 'MRT',
  MU: 'MUS',
  YT: 'MYT',
  MX: 'MEX',
  FM: 'FSM',
  MD: 'MDA',
  MC: 'MCO',
  MN: 'MNG',
  ME: 'MNE',
  MS: 'MSR',
  MA: 'MAR',
  MZ: 'MOZ',
  MM: 'MMR',
  NA: 'NAM',
  NR: 'NRU',
  NP: 'NPL',
  NL: 'NLD',
  AN: 'ANT',
  NC: 'NCL',
  NZ: 'NZL',
  NI: 'NIC',
  NE: 'NER',
  NG: 'NGA',
  NU: 'NIU',
  NF: 'NFK',
  MP: 'MNP',
  NO: 'NOR',
  OM: 'OMN',
  PK: 'PAK',
  PW: 'PLW',
  PS: 'PSE',
  PA: 'PAN',
  PG: 'PNG',
  PY: 'PRY',
  PE: 'PER',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PT: 'PRT',
  PR: 'PRI',
  QA: 'QAT',
  RE: 'REU',
  RO: 'ROU',
  RU: 'RUS',
  RW: 'RWA',
  BL: 'BLM',
  SH: 'SHN',
  KN: 'KNA',
  LC: 'LCA',
  MF: 'MAF',
  PM: 'SPM',
  VC: 'VCT',
  WS: 'WSM',
  SM: 'SMR',
  ST: 'STP',
  SA: 'SAU',
  SN: 'SEN',
  RS: 'SRB',
  SC: 'SYC',
  SL: 'SLE',
  SG: 'SGP',
  SK: 'SVK',
  SI: 'SVN',
  SB: 'SLB',
  SO: 'SOM',
  ZA: 'ZAF',
  GS: 'SGS',
  SS: 'SSD',
  ES: 'ESP',
  LK: 'LKA',
  SD: 'SDN',
  SR: 'SUR',
  SJ: 'SJM',
  SZ: 'SWZ',
  SE: 'SWE',
  CH: 'CHE',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TZ: 'TZA',
  TH: 'THA',
  TL: 'TLS',
  TG: 'TGO',
  TK: 'TKL',
  TO: 'TON',
  TT: 'TTO',
  TN: 'TUN',
  TR: 'TUR',
  TM: 'TKM',
  TC: 'TCA',
  TV: 'TUV',
  UG: 'UGA',
  UA: 'UKR',
  AE: 'ARE',
  GB: 'GBR',
  US: 'USA',
  UM: 'UMI',
  UY: 'URY',
  UZ: 'UZB',
  VU: 'VUT',
  VE: 'VEN',
  VN: 'VNM',
  VI: 'VIR',
  WF: 'WLF',
  EH: 'ESH',
  YE: 'YEM',
  ZM: 'ZMB',
  ZW: 'ZWE',
  XK: 'XKX',
}

export function convertCountryAlpha2CodeToAlpha3(alpha2Code) {
  return countryAlpha2ToAlpha3Mapping[alpha2Code]
}

export function getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice={}) {
  const { defaultCustomerText='', containerType='' } = entireItemSkusSlice?.[itemSkuId] || {}
  if (!defaultCustomerText) {
    // const containerType = getContainerTypeFromItemSkuId({ entireItemSkusSlice, itemSkuId })
    return getHumanReadableContainerType(containerType, entireItemSkusSlice)
  }
  return defaultCustomerText
}

export function createSkuQuantityQualityCellContentForExcel(
    entireItemSkusSlice,
    row,
    useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged) {
  if (!row.lineItems) {
    return {}
  }
  const ackedLineItemObj = row.lineItems.filter(lineItem => lineItem.linkType === 'ACKED')
  let linkType
  if (
      useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged
      && isPurposeOfShipmentToFulfillKegOrder(row)
      && ackedLineItemObj.length > 0
  ) {
    linkType = 'ACKED'
  } else {
    linkType = 'SHIPPED'
  }
  const sku = []
  const qualityLevel = []
  const quantity = []
  row.lineItems.filter(lineItem => lineItem.linkType === linkType && lineItem.quantity > 0)
      .forEach(({ itemSkuId, quantity: quantityObj }) => {
            // eslint-disable-next-line max-len
            const { defaultCustomerText='', qualityLevel: qualityLevelObj } = entireItemSkusSlice?.[itemSkuId] || {}
            const skuObj = `${defaultCustomerText}`
            sku.push(skuObj)
            qualityLevel.push(capitalize_(qualityLevelObj))
            quantity.push(quantityObj)
          },
      )
  return {
    sku,
    quantity,
    qualityLevel,
  }
}
export function getHumanReadableContainerQuantityfromItemSkuForShipmentHistoryFile(
    entireItemSkusSlice, row, useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged) {
  const { quantity } =createSkuQuantityQualityCellContentForExcel(
    entireItemSkusSlice,
    row,
    useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
  )
  return quantity
}

export function getHumanReadableContainerTypefromItemSkuForShipmentHistoryFile(
    entireItemSkusSlice, row, useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged) {
  const { sku } =createSkuQuantityQualityCellContentForExcel(
    entireItemSkusSlice,
    row,
    useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
  )
  return sku
}
export function getHumanReadableContainerQualityfromItemSkuForShipmentHistoryFile(
    entireItemSkusSlice ={}, row, useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged) {
  const { qualityLevel } =createSkuQuantityQualityCellContentForExcel(
    entireItemSkusSlice,
    row,
    useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
  )
  return qualityLevel
}

export function getBaseContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice={}) {
  const { baseSKU='', containerType='' } = entireItemSkusSlice?.[itemSkuId] || {}
  if (!baseSKU) {
    // const containerType = getContainerTypeFromItemSkuId({ entireItemSkusSlice, itemSkuId })
    return getHumanReadableContainerType(containerType, entireItemSkusSlice)
  }
  return baseSKU
}

export function getHumanReadableContainerTypeFromItemSkuWithQualityLevel(itemSkuId, entireItemSkusSlice={}) {
  const { defaultCustomerText='', containerType='', qualityLevel='' } = entireItemSkusSlice?.[itemSkuId] || {}
  if (!defaultCustomerText) {
    return getHumanReadableContainerType(containerType, entireItemSkusSlice)
  }
   if (defaultCustomerText && qualityLevel) {
     return `${defaultCustomerText}-${qualityLevel}`
   }
  return defaultCustomerText
}

